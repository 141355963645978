import { VisualHealthStep, ErgonomieStep, ParameterStep, EyesStep, DigitalStep } from "./Steps";

export function validations(state, step) {

  if (step === EyesStep && !state.Eyes.Frequency) return false;
  if (step === DigitalStep) {
    if (!state.Digital) return false;
    const Digital = state.Digital;
    if (!Digital.Ordinateur && !Digital.Portable && !Digital.Smartphone && !Digital.Tablette && !Digital.Liseuse && !Digital.otherProfessionalDevices) return false;
    if (!Digital.MultipleScreens) return false;
    if (!Digital.RemoteWork) return false;
    if (!Digital.SleepTime) return false;
    if (!Digital.DigitalDeviceBlueFilter) return false;
    if (!Digital.ScreenTime) return false;
  }

  if (step === VisualHealthStep) {
    if (!state.VisualHealth) return false;
    const VisualHealth = state.VisualHealth;
    if (!VisualHealth.PasVisionDefect && !VisualHealth.UnknownVisionProblem) {
      if (!VisualHealth.Myope && !VisualHealth.Hypermetrope && !VisualHealth.Astigmate && !VisualHealth.Presbyte) return false;
    }
    if (!VisualHealth.Glasses) return false;
    if (!VisualHealth.Antilumiere) return false;
    if (!VisualHealth.Ortoptie) return false;
  }

  if (step === ErgonomieStep) {
    if (!state.Ergonomy) return false;
    const Ergonomy = state.Ergonomy;
    if (!Ergonomy.Airconditioning) return false;
    if (!Ergonomy.ScreenHeight) return false;
    if (!Ergonomy.Desk) return false;
    if (!Ergonomy.Age) return false;
    if (!Ergonomy.Sex) return false;
  }
  if (step === ParameterStep) {
    if (!state.Parameters) return false;
  }
  return true;
}

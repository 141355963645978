import i18next from "i18next";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { GetCumulativeScores, GetExcercisesData, GetScores } from "./CumulativeChart";
import { Loader, Segment } from "semantic-ui-react";
import "./stats.scss";
import api from "../../services/api";
import { useSelector } from "react-redux/es/hooks/useSelector";

const Stats = () => {
  const [state, setState] = useState({ Scores: null, FatigueScore: [0], FatigueLabels: [""], VitalityData: [], ExercisesData: [], isLoading: true });
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetchData();
  }, [user]);

  const fetchData = async () => {
    const FatigueResponse = await api.get(`/api/fatigabilities`);
    let FatigueLabels = [];
    let FatigueScore = [];
    FatigueResponse.fatigabilities.forEach((element) => {
      FatigueLabels.unshift(moment(element.testdate, "YYYY-MM-DD").format("DD/MM"));
      FatigueScore.unshift(100 - element.score);
    });

    const Scores = await GetScores(user.id, user.code);

    setState({
      Scores,
      FatigueScore: FatigueScore,
      FatigueLabels: FatigueLabels,
      ExercisesData: GetExcercisesData(Scores),
      VitalityData: [{ id: "Vitality", color: "#2185d0", data: FatigueLabels.map((v, i) => ({ x: v, y: FatigueScore[i] })) }],
      isLoading: false,
    });
  };

  if (state.isLoading) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
      </Segment>
    );
  }

  const prepareData = () => {
    const data = state.VitalityData.map((serie) => {
      const counts = {};
      serie.data.forEach((point) => {
        counts[point.x] = (counts[point.x] || 0) + 1;
        if (counts[point.x] > 1) {
          point.x = "__" + point.x + ":" + Math.random(); // adjust this to change the amount of "jitter"
        }
      });
      return serie;
    });
    return data;
  };

  return (
    !state.isLoading && (
      <div id="stats-container">
        <h2>{i18next.t("dashboard.stats.scores")}</h2>
        <div className="scoresPlot">
          {GetCumulativeScores(user.id, user.code, state.ExercisesData, (Scores) => setState((prev) => ({ ...prev, Scores, ExercisesData: GetExcercisesData(Scores) })))}
        </div>
        <h2>{i18next.t("common.vitality")}</h2>
        <div id="vitality">
          <ResponsiveLine
            data={prepareData()}
            margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
            curve="monotoneX"
            xScale={{ type: "point" }}
            yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legendOffset: 36,
              legendPosition: "middle",
              format: (tick) => (tick.startsWith("__") ? tick.substring(2).split(":")[0] : tick),
            }}
            axisLeft={{ orient: "left", tickSize: 5, tickPadding: 5, tickRotation: 0, legendOffset: -40, legendPosition: "middle", tickValues: 4 }}
            colors={{ scheme: "category10" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            areaBlendMode="multiply"
            enableArea={true}
            areaBaselineValue={Math.min(...state.FatigueScore)}
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <strong>Date:</strong> {point.data.xFormatted.startsWith("__") ? point.data.xFormatted.substring(2).split(":")[0] : point.data.xFormatted};{" "}
                  <strong>{i18next.t("common.vitality")}</strong> {point.data.yFormatted}
                </div>
              );
            }}
          />
        </div>
      </div>
    )
  );
};

export default Stats;

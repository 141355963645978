import React from "react";
import { Card, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import GamesDefinition from "../../data/game-definition";
import i18next from "../../i18n";
import "./games.scss";

const gameImages = require.context("../../../public/images/games/");

const GameCard = ({ name }) => {
  const currentGame = GamesDefinition[name];

  const history = useHistory();

  const handleClick = () => {
    history.push("/description?game=" + name);
  };

  return (
    <Card style={{ width: "160px", margin: "5px", padding: 0 }} onClick={handleClick}>
      <Image src={gameImages("./" + currentGame.image)} ui={false} style={{ width: "160px", height: "108px" }} />
      <Card.Content style={{ paddingTop: "1px", paddingBottom: "1px", paddingLeft: "5px", paddingRight: "5px" }}>
        <Card.Description>{i18next.t(`games.${name}.title`)}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default GameCard;

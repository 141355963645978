export const LowFatigability = {
  Excercises: {
    hours: ['9:00', '14:00', '18:00'],
    types: ['Tonus', 'Concentration', 'Detente'],
  },
  Pauses: {
    hours: ['10:00', '12:00', '16:00'],
  },
};
export const MediumFatigability = {
  Excercises: {
    hours: ['9:00', '14:00', '16:00', '18:30'],
    types: ['Tonus', 'Tonus', 'Concentration', 'Detente'],
  },
  Pauses: {
    hours: ['10:00', '12:00', '15:00', '17:30'],
  },
};
export const HighFatigability = {
  Excercises: {
    hours: ['9:00', '11:00', '14:00', '16:00', '18:30'],
    types: ['Tonus', 'Tonus', 'Concentration', 'Detente', 'Detente'],
  },
  Pauses: {
    hours: ['10:00', '12:00', '15:00', '17:00', '18:00'],
  },
};

import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Carousel } from "react-responsive-carousel";
import Stepper from "react-stepper-horizontal";
import { Button, Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18next from "../../i18n";
import DigitalForm from "./Digital";
import ErgonomieForm from "./Ergonomie";
import EyesForm from "./Eyes";
import VisualHealthForm from "./VisualHealth";
import ParametersForm from "./Parameters";
import { ScoreCalculation } from "./ScoreCalculator";
import api from "../../services/api";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./onboarding.scss";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/auth/actions";

import {
  DeleteExistingErgonomy,
  DeleteExistingExcercises,
  DeleteExistingPauses,
  InsertErgonomy,
  InsertExcercises,
  InsertFatigability,
  InsertPauses,
  UpdateOnboardingStatus,
} from "./Services";
import { ErgonomieStep, ParameterStep, StartStep } from "./Steps";
import { validations } from "./Validations";

//@ts-ignore
let images = require.context("../../../public/images/onboarding/", true);

function Onboarding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultState = {
    Eyes: {
      Frequency: "",
      VisualProblems: "",
      Headache: false,
      Larmoiements: false,
      Sechese: false,
      Picotements: false,
      VisionFloue: false,
      Concentration: false,
      VisionDouble: false,
      Migraines: false,
      VisualFatigue: false,
      OcularDolor: false,
      asleepDifficulty: false,
      otherDisorders: false,
    },
    Digital: {
      Ordinateur: false,
      Portable: false,
      Smartphone: false,
      Tablette: false,
      Liseuse: false,
      otherProfessionalDevices: false,
      MultipleScreens: "",
      RemoteWork: "",
      SleepTime: "",
      DigitalDeviceBlueFilter: "",
      ScreenTime: "",
    },
    VisualHealth: {
      VisionDefect: "",
      Glasses: "",
      Antilumiere: "",
      Medicines: "",
      Ortoptie: "",
      Myope: false,
      Hypermetrope: false,
      Astigmate: false,
      Presbyte: false,
      UnknownVisionProblem: false,
      PasVisionDefect: false,
    },
    Ergonomy: {
      Airconditioning: "",
      ScreenHeight: "",
      Desk: "",
      Sex: "",
      Age: "",
    },
    Parameters: "",
    PreviousResults: ""
  };

  const [state, setState] = useState(defaultState);
  const [step, setStep] = useState(-1);
  const [finalScore, setFinalScore] = useState({ Percentage: 0, Level: "" });

  const user = useSelector((s) => s.Auth.user);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    let FatigueTest = await api.get(`/api/fatigabilities`);
    let Ergonomies = await api.get(`/api/ergonomie`);
    if (Ergonomies && Ergonomies.ergonomies.length > 0 && FatigueTest && FatigueTest.fatigabilities.length > 0) {
      let previousFatigabilityResults = FatigueTest.fatigabilities[0];
      let previousErgonomyParameters = Ergonomies.ergonomies[0];
      
      let previousEyesResults = { ...state.Eyes, 
        Frequency: previousFatigabilityResults.frequency
      };
      let previousDigitalResults = {
        Ordinateur: previousFatigabilityResults.ordinateur,
        Portable: previousFatigabilityResults.portable,
        Smartphone: previousFatigabilityResults.smartphone,
        Tablette: previousFatigabilityResults.tablette,
        Liseuse: previousFatigabilityResults.liseuse,
        otherProfessionalDevices: previousFatigabilityResults.otherprofessionaldevices,
        MultipleScreens: previousFatigabilityResults.multiplescreens,
        RemoteWork: previousFatigabilityResults.remotework,
        SleepTime: previousFatigabilityResults.sleeptime,
        DigitalDeviceBlueFilter: previousFatigabilityResults.DigitalDeviceBlueFilter,
        ScreenTime: previousFatigabilityResults.screentime,
      };
      let previousVisualHealthResults = {
        VisionDefect: previousFatigabilityResults.visiondefect,
        Glasses: previousFatigabilityResults.glasses,
        Antilumiere: previousFatigabilityResults.antilumiere,
        Medicines: previousFatigabilityResults.medicines,
        Ortoptie: previousFatigabilityResults.ortoptie,
        Myope: previousFatigabilityResults.myope,
        Hypermetrope: previousFatigabilityResults.hypermetrope,
        Astigmate: previousFatigabilityResults.astigmate,
        Presbyte: previousFatigabilityResults.presbyte,
        UnknownVisionProblem: previousFatigabilityResults.unknownVisionProblem,
        PasVisionDefect: previousFatigabilityResults.pasvisiondefect,
      };
      let previousErgonomyResults = {
        Airconditioning: previousErgonomyParameters.airconditioning,
        ScreenHeight: previousErgonomyParameters.screenheight,
        Desk: previousErgonomyParameters.desk,
        Sex: previousErgonomyParameters.sex,
        Age: previousErgonomyParameters.age,
      };
      setState({ ...state, Eyes: previousEyesResults, Digital: previousDigitalResults, VisualHealth: previousVisualHealthResults, Ergonomy: previousErgonomyResults, PreviousResults: FatigueTest.fatigabilities[0] });
      setStep(1);
    } else {
      setStep(0);
    }
  }

  async function handleNext() {
    if (!validations(state, step)) {
      toast.error(i18next.t("tests.answerAllQuestions"), { duration: 10000 });
      return;
    }

    if (step === ErgonomieStep) {
      let scoreCalculated = ScoreCalculation(state);
      setFinalScore(scoreCalculated);
      await InsertFatigability(user.id, state, scoreCalculated);
    }

    if (step === ParameterStep) {
      let Pauses = state.Parameters.Pauses.hours;
      if (Pauses == null) {
        Pauses = state.Parameters.Pauses;
      }
      let Excercises = { Hours: state.Parameters.Excercises.hours, Types: state.Parameters.Excercises.types };

      await DeleteExistingErgonomy(user.id);
      await InsertErgonomy(state, user.id);

      await DeleteExistingPauses(user.id);
      await InsertPauses(Pauses, user.id);

      await DeleteExistingExcercises(user.id);
      await InsertExcercises(Excercises, user.id);

      let response = await UpdateOnboardingStatus(user.id);
      if (response.ok) {
        dispatch(setUser(response.data));
      }

      toast.success(i18next.t("tests.dataSaved"), { duration: 5000 });
      history.push("/home");
      return;
    }
    setStep((step) => step + 1);
  }

  function handlePrevious() {
    if (step === StartStep) return;

    setStep((step) => step - 1);
  }

  function setEyesResults(newEyesResults) {
    setState({ ...state, Eyes: newEyesResults });
  }

  function setDigitalResults(newDigitalResults) {
    setState({ ...state, Digital: newDigitalResults });
  }

  function setVisualHealthResults(newVisualHealthResults) {
    setState({ ...state, VisualHealth: newVisualHealthResults });
  }

  function setErgonomyResults(newErgonomyResults) {
    setState({ ...state, Ergonomy: newErgonomyResults });
  }

  async function handleStateChange(name, value) {
    if (name === "Parameters") {
        setState({ ...state, Parameters: value });
        return;
      }
  }

  function ShowTutorialPage() {
    if (step < 0) return <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />;

    switch (step) {
      case 0: {
        const fr = i18next.language.indexOf("fr") !== -1;
        const slidesNumber = fr ? 4 : 6;
        const change = async (imageNumber) => {
          if (imageNumber === slidesNumber) await handleNext();
        };

        const slides = [];
        for (let i = 1; i <= slidesNumber; i++)
          slides.push(
            <div className="my-slide primary">
              <img src={images(`./${i}-${fr ? "fr" : "us"}.jpg`)} alt={`image_${i}`} />
            </div>
          );

        return (
          <Carousel showThumbs={false} showStatus={false} className="presentation-mode" onChange={change} dynamicHeight>
            <div className="my-slide primary">
              <img src={images(`./1-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary">
              <img src={images(`./2-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary">
              <img src={images(`./3-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary">
              <img src={images(`./4-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary">
              <img src={images(`./5-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary">
              <img src={images(`./6-${fr ? "fr" : "us"}.jpg`)} alt="imagew" />
            </div>
            <div className="my-slide primary"></div>
          </Carousel>
        );
      }

      case 1: {
        if (!state) return <div></div>;
        return <EyesForm eyesResults={state.Eyes} setEyesResults={setEyesResults}></EyesForm>;
      }
      case 2: {
        return <DigitalForm digitalResults={state.Digital} setDigitalResults={setDigitalResults}></DigitalForm>;
      }
      case 3: {
        return <VisualHealthForm visualHealthResults={state.VisualHealth} setVisualHealthResults={setVisualHealthResults}></VisualHealthForm>;
      }
      case 4: {
        return <ErgonomieForm ergonomyResults={state.Ergonomy} setErgonomyResults={setErgonomyResults}></ErgonomieForm>;
      }
      case 5: {
        return (
          <ParametersForm
          handleStateChange={handleStateChange}
          finalScore={finalScore}
          previousState={state.Parameters}
          PreviousResults={state.PreviousResults}
          ></ParametersForm>
        );
      }
      default: {
        return "";
      }
    }
  }

  const GetButtons = () => {
    if (step < 0) return null;

    if (step > 0)
      return (
        <div id="button-container">
          <Button type="submit" color="blue" style={{ display: step > 1 ? "block" : "none" }} onClick={handlePrevious}>
            {i18next.t("common.previous")}
          </Button>
          <Button type="submit" color="blue" onClick={handleNext}>
            {i18next.t("common.next")}
          </Button>
        </div>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <div id="new-tabcontainer">{ShowTutorialPage()}</div>
      {GetButtons()}
      <div id="stepper" style={{ display: step < 0 ? "none" : "block" }}>
        <Stepper
          activeColor={"#62997A"}
          completeColor={"#62997A"}
          defaultColor={"#62997A"}
          steps={[{ title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }]}
          activeStep={step}
        />
      </div>
    </div>
  );
}

export default Onboarding;

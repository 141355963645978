import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Card, Grid, Image, Loader, Segment } from "semantic-ui-react";
import i18next from "../../i18n";
import "./home.scss";

export function About({ state }) {
  if (state.loading) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  return (
    <Card style={{ width: "100%" }}>
      <Card.Content>
        <Card.Header className="head-title">{i18next.t("dashboard.about.title")}</Card.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <h4>{GetRandomSaviezVous()}</h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
}

function GetRandomSaviezVous() {
  let randomText = Math.floor(Math.random() * 27) + 1;
  return i18next.t(`dashboard.about.didyouknow${randomText}`);
}

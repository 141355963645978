import "moment/locale/fr";
import api from "../../services/api";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export async function UpdateOnboardingStatus(userId: any) {
  let response = await api.put("/api/users/", { onboarding: true });
  return response;
}

export async function DeleteExistingPauses(userId: any) {
  if (userId) {
    let pausesRequest = await api.get("/api/pauses?filter=" + JSON.stringify({ where: { userid: userId } }));
    pausesRequest.forEach(async (element: { id: string }) => {
      await api.delete("/api/pauses/" + element.id);
    });
  }
}

export async function DeleteExistingExcercises(userId: any) {
  if (userId) {
    let pausesRequest = await api.get("/api/exercises?filter=" + JSON.stringify({ where: { userid: userId } }));
    pausesRequest.forEach(async (element: { id: string }) => {
      await api.delete("/api/exercises/" + element.id);
    });
  }
}
export async function DeleteExistingErgonomy(userId: any) {
  if (userId) {
    let pausesRequest = await api.get("/api/ergonomie?filter=" + JSON.stringify({ where: { userid: userId } }));
    pausesRequest.ergonomies.forEach(async (element: { id: string }) => {
      await api.delete("/api/ergonomie/" + element.id);
    });
  }
}

export async function InsertPauses(Pauses: any, userId: any) {
  //@ts-ignore
  Pauses.forEach(async (element: any) => {
    await api.post("/api/Pauses/", {
      id: 0,
      userid: userId,
      pausetime: moment(element, "HH:mm").utc().format("HH:mm"),
      executed: 0,
    });
  });
}

export async function InsertExcercises(Excercises: any, userId: any) {
  for (let i = 0; i < Excercises.Hours.length; i++) {
    await api.post("/api/exercises/", {
      id: 0,
      userid: userId,
      excercisetime: moment(Excercises.Hours[i], "HH:mm").utc().format("HH:mm"),
      type: Excercises.Types[i],
      executed: 0,
    });
  }
  //@ts-ignore
}
export async function UpdateNotifications(userId: any, mailnotification: any, othermailnotification: any, pushnotification: any) {
  await api.put("/api/users", {
    userid: userId,
    mailnotification: mailnotification,
    othermailnotifications: othermailnotification,
    pushnotification: pushnotification,
  });
}

export async function InsertErgonomy(
  state: {
    step: number;
    Eyes: {
      Frequency: string;
      VisualProblems: string;
      Headache: boolean;
      Larmoiements: boolean;
      Sechese: boolean;
      Picotements: boolean;
      VisionFloue: boolean;
      Concentration: boolean;
      VisionDouble: boolean;
      Migraines: boolean;
      VisualFatigue: boolean;
      OcularDolor: boolean;
    };
    Digital: {
      Ordinateur: boolean;
      Portable: boolean;
      Smartphone: boolean;
      Tablette: boolean;
      Liseuse: boolean;
      MultipleScreens: string;
      RemoteWork: string;
      SleepTime: string;
      ScreenTime: string;
    };
    VisualHealth: {
      VisionDefect: string;
      Glasses: string;
      Antilumiere: string;
      Medicines: string;
      Ortoptie: string;
      Myope: boolean;
      Hypermetrope: boolean;
      Astigmate: boolean;
      Presbyte: boolean;
      UnknownVisionProblem: boolean;
      PasVisionDefect: boolean;
    };
    Ergonomy: { Airconditioning: string; ScreenHeight: string; Desk: string; Age: string; Sex: string };
    Parameters: string;
  },
  userId: any
) {
  await api.post("/api/ergonomie/", {
    id: 0,
    airconditioning: state.Ergonomy.Airconditioning,
    screenheight: state.Ergonomy.ScreenHeight,
    desk: state.Ergonomy.Desk,
    Age: state.Ergonomy.Age,
    Sex: state.Ergonomy.Sex,
    userid: userId,
  });
}

export async function InsertFatigability(
  userId: any,
  state: {
    Eyes: {
      Frequency: string;
      VisualProblems: string;
      Headache: boolean;
      Larmoiements: boolean;
      Sechese: boolean;
      Picotements: boolean;
      VisionFloue: boolean;
      Concentration: boolean;
      VisionDouble: boolean;
      Migraines: boolean;
      VisualFatigue: boolean;
      OcularDolor: boolean;
      asleepDifficulty: boolean;
      otherDisorders: boolean;
    };
    Digital: {
      Ordinateur: boolean;
      Portable: boolean;
      Smartphone: boolean;
      Tablette: boolean;
      Liseuse: boolean;
      otherProfessionalDevices: boolean;
      DigitalDeviceBlueFilter: string;
      MultipleScreens: string;
      RemoteWork: string;
      SleepTime: string;
      ScreenTime: string;
    };
    VisualHealth: {
      VisionDefect: string;
      Glasses: string;
      Antilumiere: string;
      Medicines: string;
      Ortoptie: string;
      Myope: boolean;

      Hypermetrope: boolean;
      Astigmate: boolean;
      Presbyte: boolean;
      UnknownVisionProblem: boolean;
      PasVisionDefect: boolean;
    };
    Ergonomy: { Airconditioning: string; ScreenHeight: string; Desk: string; Age: string; Sex: string };
    Parameters: string;
  },
  finalScore: any
) {
  await api.post("/api/fatigabilities/", {
    id: 0,
    userid: userId,
    frequency: state.Eyes.Frequency,
    visualproblems: state.Eyes.VisualProblems,
    headache: state.Eyes.Headache,
    larmoiements: state.Eyes.Larmoiements,
    sechese: state.Eyes.Sechese,
    picotements: state.Eyes.Picotements,
    visionfloue: state.Eyes.VisionFloue,
    concentration: state.Eyes.Concentration,
    visiondouble: state.Eyes.VisionDouble,
    migraines: state.Eyes.Migraines,
    visualfatigue: state.Eyes.VisualFatigue,
    oculardolor: state.Eyes.OcularDolor,
    asleepDifficulty: state.Eyes.asleepDifficulty,
    otherDisorders: state.Eyes.otherDisorders,
    ordinateur: state.Digital.Ordinateur,
    portable: state.Digital.Portable,
    smartphone: state.Digital.Smartphone,
    tablette: state.Digital.Tablette,
    liseuse: state.Digital.Liseuse,
    otherProfessionalDevices: state.Digital.otherProfessionalDevices,
    multiplescreens: state.Digital.MultipleScreens,
    DigitalDeviceBlueFilter: state.Digital.DigitalDeviceBlueFilter,
    remotework: state.Digital.RemoteWork,
    sleeptime: state.Digital.SleepTime,
    screentime: state.Digital.ScreenTime,
    visiondefect: state.VisualHealth.VisionDefect,
    glasses: state.VisualHealth.Glasses,
    antilumiere: state.VisualHealth.Antilumiere,
    medicines: state.VisualHealth.Medicines,
    ortoptie: state.VisualHealth.Ortoptie,
    testdate: new Date(),
    level: finalScore.Level,
    score: finalScore.Percentage,
    myope: state.VisualHealth.Myope,
    hypermetrope: state.VisualHealth.Hypermetrope,
    astigmate: state.VisualHealth.Astigmate,
    presbyte: state.VisualHealth.Presbyte,
    unknownvisionproblem: state.VisualHealth.UnknownVisionProblem,
    pasvisiondefect: state.VisualHealth.PasVisionDefect,
    Sex: state.Ergonomy.Sex,
    Age: state.Ergonomy.Age,
  });
}

import i18next from "i18next";
import React from "react";

class LegalMentions extends React.Component {
  render() {
    return (
      <div style={{ margin: '1em', maxWidth: '50em' }}>
        <h1 style={{ fontSize: '1.4em' }}>{i18next.t('legal.title')}</h1>

        <h2>CONDITIONS GENERALES D'UTILISATION DE L'APPLICATION VIZYGOOD (« CGU »)</h2>
        <i>Mises à jour et applicables à compter du 25/06/2022</i>

        <h4>Préambule</h4>
        <p>Les CGU sont conclues entre d'une part la société par actions simplifiée, au capital de 34000 euros, au numéro
          T.V.A. FR 84 821 660 446, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro RCS 821 660
          446, ayant son siège au 42 rue de Maubeuge, 75009 Paris et étant représentée par sa Présidente, Madame Nathalie
          WILLART (ci-après, « Coach For Eyes ») et d'autre part, toute personne physique disposant de la pleine capacité
          juridique (ci-après, l'«Utilisateur»).</p>
        Les termes avec une majuscule ont le sens qui leur est donné dans les CGU et/ou les Conditions Générales d'Utilisation
        de Coach For Eyes.

        <h3>OBJET</h3>
        Coach For Eyes SAS édite une application innovante, Vizygood, destinée à la prévention de la fatigue visuelle
        digitale. Les CGU s'appliquent à l'utilisation et aux accès aux sites internet et Application mobile suivants :
        <ul>
          <li>vizygood.fr</li>
          <li>vizygood.com</li>
          <li>L'application mobile Vizygood</li>
        </ul>
        et à tous les autres sites associés, le cas échéant (ci-après le « Site » ou les « Sites », le cas échéant).
        <p>Coach For Eyes se réserve le droit, et à sa seule discrétion, de modifier librement les CGU à tout moment et invite
          l'Utilisateur à les consulter régulièrement. En cas de modification des CGU, les CGU applicables aux Sites seront
          celles en vigueur au moment de la commande de l'Utilisateur sur les Sites. Les CGU entrent en vigueur dès leur
          publication sur les Sites et remplacent immédiatement les anciennes CGU pour toutes les opérations à venir.</p>

        <h3>MISE EN GARDE</h3>
        <b>
          <p>Les Sites développés par la société Coach For Eyes SAS ont pour objectif d'améliorer le bien-être visuel des
            Utilisateurs face aux écrans, et donne à ses Utilisateurs des recommandations en matière de prévention.</p>
          <p>L'Utilisateur est expressément informé que Coach For Eyes SAS n'est pas un prestataire de santé ni un
            établissement de santé et n'est pas en mesure de fournir des conseils médicaux et n'a pas vocation à collecter de
            la donnée relative à la santé. Les Services que la Société fournit ne contiennent pas, ne constituent pas, et ne
            devraient pas être interprétés comme des conseils médicaux ou des avis médicaux.</p>
          Par conséquent la société Coach For Eyes invite l'Utilisateur des Sites à consulter systématiquement un
          professionnel de santé qualifié en cas de doute sur son état de santé. Vizygood n'est pas un outil de diagnostic ou
          de prévention des maladies. L'utilisation de l'Application ou des Sites ne crée pas une relation médecin-patient
          entre l'Utilisateur et la Société.
        </b>

        <h3>EDITEUR</h3>
        <p>Les Sites et Application sont édités par Coach For Eyes. Le directeur de publication est Mme Nathalie WILLART. Les
          Sites et l'Application sont hébergés par Microsoft Azure dans des serveurs localisés en France.</p>
        Pour toute information ou question, Coach For Eyes est à votre disposition :
        <ul>
          <li>Par courrier électronique : hello@coachforeyes.com ;</li>
          <li>Par courrier postal à l'adresse suivante : Coach For Eyes, SAS - 42 rue de Maubeuge, 75009 Paris</li>
        </ul>

        <h2>VOS DROITS ET OBLIGATIONS</h2>
        <h3>ACCEPTATION DES CGU</h3>
        Les CGU définissent vos relations contractuelles avec Coach For Eyes, ainsi que les conditions dans lesquelles les
        Sites peuvent être utilisés. Les CGU ainsi que, le cas échéant, nos Conditions Générales de Vente, expriment
        l'intégralité des droits et obligations de l'Utilisateur et de Coach For Eyes et prévaudront sur toutes autres
        conditions générales ou particulières non expressément agréées par Coach For Eyes.
        <p>En accédant aux Sites ou à l'Application, l'Utilisateur accepte de manière entière, inconditionnelle et sans
          réserve ces CGU. L'Utilisateur ne doit en aucun cas utiliser nos Sites ou Application, s'il ne souscrit pas à ces
          CGU. Dans le cas où l'Utilisateur ne souhaite pas accepter tout ou partie des CGU, il lui est demandé de renoncer à
          tout usage des Sites ou Application.</p>

        <h3>UTILISATION DE L'APPLICATION ET DES SITES VIZYGOOD</h3>
        En se connectant aux Sites ou à l'Application, l'Utilisateur certifie être majeur et juridiquement capable et accepte
        sans réserve les CGU. Pour pouvoir utiliser le Site ou l'Application l'Utilisateur doit disposer d'un téléphone
        compatible iOS ou Android ou d'un navigateur web ainsi que d'un accès Internet (coût d'accès supporté par
        l'Utilisateur), ainsi que d'un compte personnel.
        <p>Compte tenu des contraintes techniques informatiques et de télécommunications inhérentes à l'usage de l'Application
          et du Site web, la responsabilité de Coach For Eyes ne pourra être engagée en cas de dommage causé par un
          dysfonctionnement de l'une ou de plusieurs des étapes d'accès à l'Application et/ou du Site proposé, dû notamment
          mais non exclusivement, aux inconvénients inhérents à l'utilisation du réseau Internet, tels que rupture de service,
          intrusion extérieure, présence de virus informatiques ou dysfonctionnement de quelque nature que ce soit.</p>
        <p>L'Application et les Sites supportent les navigateurs suivants :</p>
        <ul>
          <li>Google Chrome™ : dernières versions stables</li>
          <li>Mozilla® Firefox® : dernières versions stables</li>
          <li>Apple® Safari® pour macOS : dernières versions stables</li>
          <li>Microsoft® Edge® : dernières versions stables</li>
        </ul>
        Pour une expérience optimale, il est recommandé aux Utilisateurs d'utiliser la version la plus récente de leur
        navigateur supportée par Vizygood.
        <p>Les cookies et Javascript doivent être activés pour pouvoir utiliser le service.</p>
        L'Utilisateur est invité à remonter sans délai à Coach For Eyes toute difficulté qu'il viendrait à constater dans le
        cadre de son utilisation de l'Application ou du Site.
        <p>Coach For Eyes ne garantit pas un fonctionnement optimal de Vizygood si la version utilisée ne correspond pas à la
          dernière version de l'Application mobile sur les plateformes de téléchargement (Google Play et Apple Store). La
          responsabilité de Coach For Eyes ne pourra être engagée en cas de dommage causé par un dysfonctionnement sur une
          version antérieure de l'Application.</p>
        L'Utilisateur est seul responsable des activités entreprises par le biais de son compte, ainsi que du maintien de la
        confidentialité de son mot de passe. L'Utilisateur ne devra jamais utiliser le compte d'une autre personne.
        L'Utilisateur devra informer immédiatement Coach For Eyes par écrit de toute utilisation non autorisée de son compte
        ou de toute autre violation de la sécurité liée à son compte dont il aurait connaissance.

        <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
        Coach For Eyes est et reste seul propriétaire des logiciels, des Sites, des méthodes, du processus, des
        questionnaires, des outils d'analyse, des techniques, des développements et du savoir-faire utilisés dans
        l'Application ou les Sites Vizygood, ce que le l'Utilisateur reconnaît expressément.
        <p>A ce titre, Coach For Eyes est investi de l'intégralité des droits, y compris des droits d'auteur, sur
          l'Application et Sites de Coach For Eyes, conformément au Code de la propriété intellectuelle.</p>
        <p>Toute reproduction et/ou représentation de l'Application ou Sites de Coach For Eyes, qu'elle soit totale ou
          partielle, quelle qu'en soit la forme est interdite</p>
        <p>L'Utilisateur n'a aucun droit de propriété ou privatif d'aucune sorte sur l'Application et Sites de Coach For Eyes,
          l'Utilisateur ne disposant que d'un droit d'utilisation temporaire et partiel sur l'Application et Sites de Coach
          For Eyes.</p>
        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo,
          sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner
          l'Application et Sites et plus généralement tous les éléments reproduits ou utilisés sur l'Application et Sites sont
          protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
        <p>Ils sont la propriété pleine et entière de Coach For Eyes ou de ses partenaires. Toute reproduction,
          représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y
          compris les applications informatiques, sans l'accord préalable et écrit de Coach For Eyes, sont strictement
          interdites. Les Contenus sont accessibles à l'Utilisateur dans le cadre d'un usage personnel, privé, non collectif
          et non commercial. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des Contenus
          proposés par le Site, par quelque procédé que ce soit, sans l'autorisation préalable et par écrit de Coach For Eyes
          est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L 335-2 et
          suivants du Code de la propriété intellectuelle. Le fait pour Coach For Eyes de ne pas engager de procédure dès la
          prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et
          renonciation aux poursuites.</p>
        <p>VIZYGOOD est une marque française semi-figurative appartenant à Coach For Eyes, déposée à l'INPI.</p>
        <p>COACH FOR EYES est une marque française appartenant à Coach For Eyes, déposée à l'INPI.</p>
        <p>Les Contenus désignent l'ensemble des informations quel que soit le format (texte, image, video, etc.) mises à
          votre disposition par Coach For Eyes. L'Utilisateur est seul responsable de l'utilisation qu'il fait des Contenus
          présents sur le Site et, en conséquence, la société Coach For Eyes ne saurait être tenue responsable de dommages
          directs ou indirects découlant de l'utilisation de ces Contenus ; L'Utilisateur est seul responsable de
          l'utilisation du Contenu des Sites ayant un lien hypertexte, Coach For Eyes déclinant toute responsabilité quant à
          ces Contenus conformément aux dispositions infra ; il appartient à l'Utilisateur de prendre toutes mesures
          nécessaires pour s'assurer que les caractéristiques techniques de son équipement lui permettent la consultation des
          Contenus des Sites.</p>

        <h3>POLITIQUE D'UTILISATION ACCEPTABLE</h3>
        L'Utilisateur ne doit pas faire ou tenter de faire ou encourager un Utilisateur ou un tiers à faire ou à tenter de
        faire l'une des choses suivantes en accédant et en utilisant les Services de Coach For Eyes :
        <ul>
          <li>Reconstituer la logique, décomposer, modifier, altérer, fausser, démonter, ou créer un travail dérivé issu de
            tous logiciels utilisés pour les Sites et Application.</li>
          <li>Utiliser les Sites ou Application pour contrevenir consciemment ou délibérément, aux droits de propriété
            intellectuelle de tiers, notamment, leurs brevets, droit d'auteur, marque déposée, secret de fabrication, ou tous
            autres droits intellectuels applicables tels que ceux qui existent aujourd'hui ou qui viendraient à exister,
            partout dans le monde.</li>
          <li>Accéder aux Sites ou à l'Application à l'aide de méthodes automatisées (telles que robots, araignées, etc.),
            sauf autorisation préalable écrite de Coach For Eyes</li>
          <li>Utiliser les Sites ou Application dans un but illicite, illégal, malveillant ou discriminatoire.</li>
          <li>Entraver le bon fonctionnement des Sites ou Application.</li>
        </ul>
        Vous reconnaissez que toute violation des CGU permet à Coach For Eyes, nonobstant tout recours à votre encontre, de
        prendre des mesures afin de restreindre votre accès aux Sites ou Application.

        <h3>DISPONIBILITÉ DU SITE</h3>
        Coach For Eyes s'engage à mettre en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de
        qualité au Site à tout moment, 24h sur 24h et 7 jours sur 7, mais n'a aucune obligation d'y parvenir, en particulier
        en cas de force majeure, de dysfonctionnement du réseau, des serveurs, ou de tout autre événement échappant à son
        contrôle.
        <p>Coach For Eyes pourra interrompre, suspendre ou modifier temporairement et sans préavis l'accès au Site, notamment
          pour des raisons de sécurité, pour la restructuration de ressource machine, pour la maintenance ou l'amélioration
          des Sites ou pour améliorer la disponibilité des informations via Internet. Dans la mesure du possible, Coach For
          Eyes s'engage à diffuser un message vous informant de cette interruption du Site. Dans tous les cas, Coach For Eyes
          fera ses meilleurs efforts afin que l'interruption vous affecte le moins possible. Coach For Eyes ne peut être tenue
          pour responsable d'éventuels dommages occasionnés par ces interruptions, en ce compris les pertes de données.</p>
        Par conséquent, Coach For Eyes ne peut garantir une disponibilité du Site, une fiabilité des transmissions et des
        performances en termes de temps de réponse ou de qualité.

        <h3>CRÉATION D'HYPERLIENS</h3>
        Vizygood peut renvoyer à des sites internet, applications, produits ou services de tiers. Coach For Eyes ne saurait
        être responsable des pratiques auxquelles se livrent les sites internet tiers rendus accessibles ou auxquels renvoient
        nos Sites, ni de leurs informations ou contenus.
        <p>Lorsqu'un Utilisateur utilise un lien vers un autre site internet, ces CGU ne sont plus applicables.</p>

        <h3>RÉSILIATION</h3>
        Coach For Eyes se réserve également le droit de suspendre ou de fermer un compte Utilisateur à tout moment et sans
        préavis, si ce dernier ne respecte pas ces CGU ou s'il utilise les Sites en violation de la Politique d'Utilisation
        Acceptable ou des dispositions légales ou réglementaires en vigueur.
        <p>La désactivation de votre compte, quelle qu'en soit la cause, ne pourra en aucun cas donner lieu au versement d'une
          quelconque indemnité. Elle prend effet immédiatement.</p>

        <h3>RESPONSABILITÉS ET GARANTIES</h3>
        Les engagements de Coach For Eyes pris aux termes des présents CGU constituent une obligation de moyens.
        <p>Coach For Eyes ne dispose d'aucun contrôle sur l'utilisation ou l'interprétation faite des informations et conseils
          figurant sur les Sites, elles relèvent de la seule responsabilité des Utilisateurs. Dans les limites permises par la
          loi, en aucun cas Coach For Eyes, ses filiales, distributeurs ou fournisseurs ne pourront être tenus responsables de
          tout préjudice direct, indirect et/ou immatériel, tel la perte ou destruction des données, la perte des profits et
          bénéfices réels ou éventuels, quelle qu'en soit la cause et sur quelque fondement que ce soit, que Coach For Eyes
          ait été ou non informée de la possible réalisation de tels dommages.</p>
        <p>En cas de condamnation de Coach For Eyes, le montant des condamnations prononcées à son encontre ne saurait excéder
          la plus forte de ces deux sommes : (i) le montant total des sommes versées à Coach For Eyes durant les six (6)
          derniers mois, à l'exclusion du prix d'achat du produit ou (ii) cent (100,00) euros.</p>

        <h3>RÉSOLUTION DES LITIGES</h3>
        Avant d'intenter une action ou un recours à l'encontre de Coach For Eyes, l'Utilisateur consent à essayer de résoudre
        le litige de manière amiable en contactant Coach For Eyes à cette adresse : hello@coachforeyes.com ou à
        nathalie@coachforeyes.com.
        <p>Toute réclamation, amiable ou judiciaire, relative à l'exécution ou l'interprétation des CGU devra être formulée
          dans un délai d'un (1) an à compter du fait générateur ou de la désactivation du compte, à peine de prescription.
          Tout litige auquel l'utilisation des Sites pourrait donner lieu ou étant relatif à l'exécution ou l'interprétation
          des CGU relèvera du droit français et de la compétence exclusive du tribunal de Commerce de Paris.</p>

        <h3>NOTIFICATIONS</h3>
        En autorisant la collecte des ses données personnelles, l'Utilisateur autorise par ces présentes CGU Coach For Eyes à
        envoyer toutes notifications à l'adresse email utilisée pour créer son compte et les informations de la newsletter, en
        fonction de son choix. Afin d'écarter tous les doutes, l'Utilisateur sera considéré comme ayant reçu toute
        notification envoyée en vertu de ces présentes CGU, lors de leur envoi par Coach For Eyes. Si l'Utilisateur ne
        souhaite plus recevoir ces notifications par email, il doit en faire la demande par email à l'adresse suivante :
        hello@coachforeyes.com

        <h3>INTERPRÉTATION</h3>
        En cas de difficulté d'interprétation entre l'un quelconque des titres figurant en tête des clauses, et l'une
        quelconque des clauses, les titres seront déclarés inexistants.

        <h3>NULLITÉ</h3>
        La nullité d'une clause n'entraîne pas la nullité des CGU, à l'exception de celle d'une clause impulsive et
        déterminante ayant amené l'une des Parties à contracter. La ou les stipulations annulées sont réputées non écrites.

        <h3>TOLÉRANCE</h3>
        Le fait que l'une ou l'autre des Parties ne se prévale pas un moment donné de l'une des quelconque clause ou qu'elle
        tolère l'inexécution de façon temporaire ou permanente des obligations de l'autre Partie ne peut être interprété comme
        valant renonciation à se prévaloir ultérieurement. Le fait pour l'une ou l'autre des Parties de tolérer une
        inexécution ou une exécution imparfaite des présentes CGU ou, plus généralement, de tolérer tout acte, abstention ou
        omission de l'autre Partie non conforme aux dispositions des présentes CGU ne saurait conférer un droit quelconque à
        la Partie bénéficiant de cette tolérance.

        <h3>DROIT APPLICABLE</h3>
        De convention expresse entre les Parties, les présentes Conditions Générales d'Utilisation sont soumises au droit
        français, à l'exclusion de toute autre législation.
        <p>Tout litige relatif à la conclusion, l'interprétation, l'exécution des Conditions d'Utilisation sera soumis au
          tribunal de commerce de Paris exclusivement compétent, y compris en référé, nonobstant appel en garantie ou
          pluralité de défendeurs.</p>

        <h2>TRAITEMENT DES DONNEES ET CONFIDENTIALITE</h2>
        <p><b>Les présentes clauses ont pour objet de définir les conditions dans lesquelles Coach For Eyes (ci-après le
          Sous-Traitant) s'engage à effectuer pour le compte de l'Utilisateur les opérations de traitement de données à
          caractère personnel définies ci-après.</b></p>
        <p><b>Dans le cadre de leurs relations contractuelles, les parties s'engagent à respecter la réglementation en vigueur
          applicable au traitement de données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du
          Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après, « le règlement
          européen sur la protection des données »).</b></p>
        <p>L'Utilisateur est informé qu'au sens des CGU, les données à caractère personnel désignent, conformément à l'article
          4 du règlement général européen relatif à la protection des données, « toute information se rapportant à une
          personne physique identifiée ou identifiable (ci-après dénommée « personne concernée »); est réputée être une
          «personne physique identifiable» une personne physique qui peut être identifiée, directement ou indirectement,
          notamment par référence à :</p>
        <ul>
          <li>Un identifiant, tel qu'un nom,</li>
          <li>Un numéro d'identification,</li>
          <li>Des données de localisation,</li>
          <li>Un identifiant en ligne,</li>
          <li>Ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique,
            économique, culturelle ou sociale ».</li>
        </ul>
        En poursuivant votre navigation sur le Site, l'Utilisateur accepte expressément que Coach For Eyes collecte des
        données à caractère personnel le concernant de manière directe et indirecte.
        <p>Coach For Eyes collecte les catégories de données suivantes : Identité et état civil (nom, prénom, adresse
          électronique, date de naissance), mot de passe pour la création du compte, données de connexion (adresse IP et
          statistiques d'utilisation), réponses aux questionnaires de Vizygood. Ces données sont utilisées uniquement pour
          optimiser les services et les recommandations de Vizygood.</p>
        <p>L'accès aux Sites nécessite la création par l'Utilisateur d'un mot de passe pour la création du compte utilisateur
          Vizygood. Ce mode de passe est strictement personnel et confidentiel, l'Utilisateur s'engage à le conserver secret
          et s'interdit de le divulguer sous quelque forme que ce soit à des tiers. L'Utilisateur est responsable de la
          protection de son identifiant et mot de passe. Sauf preuve contraire, toute connexion aux Sites ou transmission de
          données effectuées à partir des codes d'accès de l'Utilisateur sera réputée avoir été effectuée par ce dernier. En
          cas de perte ou de vol des identifiants et/ou mot de passe ou d'utilisation du mot de passe par un tiers non
          autorisé, l'Utilisateur doit immédiatement informer Coach For Eyes à l'adresse suivante, afin de révoquer ces
          identifiant et mot de passe et d'en obtenir de nouveaux : nathalie@coahforeyes.com. Coach For Eyes ne saurait en
          aucun cas être tenue pour responsable des accès au Site de tiers non autorisés permis par l'utilisation illicite de
          des identifiant et mot de passe.</p>

        <h3>FINALITÉ DE TRAITEMENT DES DONNÉES PERSONNELLES</h3>
        Les données à caractère personnel font l'objet d'un traitement mis en œuvre par Coach For Eyes dans le but exclusif de
        fournir, exploiter, entretenir et améliorer les services pour une meilleure prévention de la fatigabilité visuelle.
        <p>Le traitement mis en œuvre par Coach For Eyes a les finalités suivantes :</p>
        <ul>
          <li>Création du compte utilisateur</li>
          <li>Effectuer les opérations relatives à la gestion des Utilisateurs ;</li>
          <li>Le suivi de la relation Utilisateur tel que la réalisation d'enquêtes de satisfaction, la gestion des demandes
            Utilisateurs et des notifications ;</li>
          <li>Effectuer des opérations relatives à l'élaboration de statistiques ;</li>
          <li>La gestion des demandes de droit d'accès, de rectification, d'opposition, d'oubli, à la limitation du
            traitement, d'information, à la portabilité des données, testament relatif aux données et d'opposition au
            profilage.</li>
        </ul>
        Coach For Eyes garantir avoir satisfait à l'ensemble des obligations légales relatives à la protection des données à
        caractère personnel en vigueur, et notamment avoir effectué toutes les formalités préalables auprès de l'autorité
        française de protection des données, la Commission Nationale de l'Informatique et des Libertés (CNIL), qui lui incombe
        au titre de la mise en œuvre du traitement de données à caractère personnel.

        <h3>SÉCURITÉ - CONFIDENTIALITÉ - DESTINATAIRES</h3>
        La protection des données à caractère personnel constitue un point central de la politique de confidentialité de Coach
        For Eyes. Nous prenons ainsi l'engagement d'assurer la confidentialité de toutes les données à caractère personnel
        vous concernant en ce compris celles résultantes de l'utilisation de nos Sites.
        <p>Coach For Eyes s'engage à mettre en place toutes mesures utiles de protection de nature physique, organisationnelle
          et technique pour protéger vos données à caractère personnel contre toute perte, utilisation inappropriée, accès non
          autorisé, divulgation, modification et/ou destruction.</p>
        <p>En dehors des finalités de traitement mentionnées ci-dessus, les données à caractère personnel ne seront ni
          vendues, ni échangées, ni transférées, ni données sans le consentement explicite de l'Utilisateur, et ce pour
          quelque raison que ce soit.</p>
        <p>En revanche, lorsque la réglementation en vigueur l'exige, Coach For Eyes est susceptible de transmettre les
          données personnelles des Utilisateurs aux organismes et autorités légalement autorisés à y accéder (notamment les
          autorités judiciaires et administratives).</p>
        <p>Coach For Eyes n'intervient pas en qualité d'hébergeur de données de santé au sens de l'article L1111-8 du Code de
          la Santé Publique et n'a pas recours à un tel prestataire.</p>
        <p>En cas de sous-traitance de l'hébergement de vos données à caractère personnel, Coach For Eyes s'oblige à reporter
          contractuellement sur le prestataire l'ensemble des obligations stipulées dans le règlement général européen sur la
          protection des données et les obligations réglementaires en vigueur.</p>

        <h3>DURÉE DE CONSERVATION DES DONNÉES</h3>
        Coach For Eyes conserve les données à caractère personnel pendant une durée qui n'excède pas la durée nécessaire aux
        finalités exposées par les présente. Au-delà, les données à caractère personnel sont susceptibles d'être archivées
        pour respecter les obligations légales et réglementaires auxquelles Coach For Eyes est soumise ou détruites et dans
        tous les cas pas plus de dix (10) ans sauf contentieux pendant.
        <p>Les données à caractère personnel permettant d'établir la preuve d'un droit ou d'un contrat, ou conservées au titre
          du respect d'une obligation légale par Coach For Eyes sont archivées conformément aux dispositions en vigueur.</p>

        <h3>TRANSFERT(S) DE DONNÉES HORS DE L'UNION EUROPÉENNE</h3>
        Dans le cadre de la fourniture des Services, Coach For Eyes est susceptible d'avoir recours à des prestataires
        d'analyses et de notification (“sous-traitants ultérieurs”) dont les serveurs sont situés dans des pays non-membres de
        l'Union Européenne et qui n'ont pas fait l'objet d'une décision d'adéquation de protection de la Commission
        Européenne. Dans ce cas, Coach For Eyes s'assure que ce transfert soit réalisé dans des conditions conformes à la
        réglementation en vigueur en prenant toutes les mesures appropriées et dans un cadre juridique, technique et
        opérationnel propre à assurer la confidentialité et la sécurité de ces données.

        <h3>DROIT D'ACCÈS, DE RECTIFICATION, D'OPPOSITION ET SUPPRESSION</h3>
        Conformément à la réglementation relative à la protection des données à caractère personnel, l'Utilisateur dispose
        d'un droit d'accès, de rectification, de suppression de ses données personnelles et d'opposition au traitement, ainsi
        que du droit de définir le sort de ses données après son décès.
        <p>L'Utilisateur peut exercer ces droits et formuler ses demandes en adressant un courriel ou un courrier, accompagné
          d'une pièce d'identité, à l'adresse suivante hello@coachforeyes.com - Coach For Eyes, 42 rue de Maubeuge - 75009
          Paris ou en utilisant le formulaire de contact mis à sa disposition sur le Site.</p>

        <h3>COOKIES</h3>
        Lors de votre utilisation des Sites, des cookies sont susceptibles d'être installés sur le terminal de l'Utilisateur.
        Ainsi, lors de la première connexion sur les Sites, l'Utilisateur est informé de l'utilisation de cookies et son
        consentement à l'installation ainsi qu'à l'exploitation de ces cookies sera demandé par tout procédé technique
        adéquat.
        <p>Certaines données sont collectées et traitées automatiquement du fait de l'utilisation par l'Utilisateur des Sites.
          Il s'agit entre autres de l'adresse IP, des caractéristiques du navigateur, de la langue courant, du logiciel
          utilisé par le terminal de l'Utilisateur, des données de navigations et de connexions…Sont effectuées également des
          mesures d'audiences, par lesquelles sont mesurés le nombre de pages vues, l'activité, la fréquence des retours sur
          le Site…</p>
        <p>Pour cela Coach For Eyes a mis en place sur le Site, des procédés automatiques de traçage appelé cookies (cookies
          de session et cookies permanents). Les cookies sont de petits fichiers de données enregistrés sur le terminal de
          chaque Utilisateur (ordinateur, tablette, téléphone portable ou appareil…) lors de sa navigation sur les Sites
          jusqu'à expiration de leur durée de vie ou jusqu'à ce que l'Utilisateur les désactive ou les supprime à l'aide des
          fonctionnalités de son navigateur. Ces cookies permettent à notre Site de reconnaître le navigateur de chaque
          Utilisateur et de saisir et conserver certaines informations parmi lesquelles figurent des données à caractère
          personnel.</p>

        <p>Les cookies déposés et lus sur le disque dur de votre terminal par Coach For Eyes ont les finalités suivantes :</p>
        <ul>
          <li>Réaliser de la mesure d'audience à savoir de la mesure de la fréquentation et de l'utilisation des Sites
            (contenus, rubriques, temps passé, navigateur utilisé ;</li>
          <li>Améliorer l'interactivité des Sites pour permettre aux Utilisateurs de rejoindre les canaux de communication de
            Coach For Eyes (utilisation des boutons de réseaux sociaux);</li>
          <li>Comprendre et conserver vos préférences pour vos prochaines visites du Site.</li>
        </ul>

        Conformément aux principes relatifs à la protection des données, l'Utilisateur est informé que les cookies sont
        conservés pendant la durée strictement nécessaire aux finalités pour lesquelles ils sont utilisés et ce, dans les
        limites définies par l'autorité de protection compétente, soit treize (13) mois à compter de leur placement.
        Pour désactiver les cookies, il appartient à chaque Utilisateur qui le souhaite de paramétrer son navigateur. Les
        procédures sont décrites soit dans le menu d'aide du navigateur, soit sur le site internet de l'éditeur. En cas de
        désactivation, certaines fonctionnalités du Site risquent de ne plus être opérationnelles.
        Par ailleurs, si le navigateur de l'Utilisateur est configuré pour refuser certains cookies temporaires, l'accès au
        Site peut se révéler altéré, voire impossible.
      </div>
    )
  }
}

export default LegalMentions;

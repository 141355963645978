import React, { useState, useRef, useEffect } from 'react';
import './home.scss';
import axios from '../../helpers/axios';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

const Pauses = () => {
  const handle = useFullScreenHandle();

  useEffect(() => {
    // you can place your async componentDidMount actions here
  }, []);

  return (
    <div>
      <button onClick={handle.enter}>
        Go Fullscreen
      </button>

      <FullScreen handle={handle}>
        <div className="full-screenable-node">
          Hi! This may cover the entire monitor.
        </div>
      </FullScreen>
    </div>
  );
};

export default Pauses;
import React, { Component } from "react";
import { Icon, Menu, Image } from "semantic-ui-react";
import { useSelector } from "react-redux";

let images = require.context("../../../public/images/", true);

export default function MobileHeader() {
  return (
    <Menu style={{ flexShrink: 0, margin: 0 }}>
      <Menu fluid mini icon="labeled">
        <Menu.Item name="score" className="MenuButtonHeader">
          <Icon style={{ paddingTop: "15px" }} id="star-points" color="yellow" name="star" big />
          <ScoreStat />
        </Menu.Item>
        <Menu.Item className="MenuButtonHeader">
          <Image id="vizy-head" src={images("./vizy-head.png")} />
        </Menu.Item>
      </Menu>
    </Menu>
  );
}

const ScoreStat = () => {
  const value = useSelector((s) => s.currentScoreReducer.currentScore);
  return <p style={{ paddingTop: "15px", fontSize: "smaller" }}>{value} pts</p>;
};

import moment, { Moment } from 'moment';
import TimePicker from 'rc-time-picker';
import React from 'react';
import { Form } from 'semantic-ui-react';
import './onboarding.scss';
interface IProps {
  handleStateChange: (value: any) => any;
  Excercises: {number: number; hours: string[]} | undefined;
  previousState: any;
}
export default class ExcercisesForm extends React.Component<IProps> {
  state = {
    Excercises: Array<string>(),
  };

  handleChange = async (name: any, newValue: Moment) => {
    let state = {...this.state};
    let index = state.Excercises.indexOf(name);
    if (index !== -1) {
      if (newValue)
        state.Excercises[index] = newValue.format('HH:mm');
      else
      state.Excercises = state.Excercises.filter((item, idx) => idx !== index);
      await this.props.handleStateChange(state);
    }

  };
  componentDidMount() {
    if (this.props.previousState) {
      let state = {...this.state};
      state = this.props.previousState;
      this.setState(state);
    }
    if (this.props.Excercises) {
      let state = {...this.state};
      state.Excercises = this.props.Excercises.hours;
      this.setState(state);
    }
  }
  componentWillReceiveProps(nextProps: any) {
    this.state.Excercises = nextProps.Excercises.hours;
  }

  render() {
    let context = this;
    return (
      <Form id="fatigue-form">
        <Form.Field>
          {this.state.Excercises.sort().map(function(name, index) {
            return (
              <div key={name}>
                <TimePicker
                  onChange={newValue => context.handleChange(name, newValue)}
                  defaultValue={moment(
                    context.state.Excercises[index],
                    'HH:mm'
                  )}
                  showSecond={false}
                />
              </div>
            );
          })}
        </Form.Field>
      </Form>
    );
  }
  handleAdd = async () => {
    let state = {...this.state};
    state.Excercises.push('');
    await this.props.handleStateChange(state);
    this.setState(state);
  };
  handleRemove = async () => {
    let state = {...this.state};
    state.Excercises.pop();
    await this.props.handleStateChange(state);
    this.setState(state);
  };
}

import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Button, Form, Modal, Radio } from "semantic-ui-react";
import ExcercisesForm from "../onboarding/ExcercisesComponent";
import PausesForm from "../onboarding/PausesComponent";
import i18next from "../../i18n";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { DeleteExistingExcercises, DeleteExistingPauses, InsertExcercises, InsertPauses, UpdateNotifications } from "../onboarding/Services";
import api from "../../services/api";
import toast from "react-hot-toast";

const ParametersModal = ({ parametersModalShow, handleClose }) => {
  const [state, setState] = useState({
    Pauses: null,
    Excercises: null,
    Percentage: 0,
    Results: { Excercises: { number: 0, hours: [], types: [] }, Pauses: { number: 0, hours: [] } },
    MailNotifications: null,
    OtherMailNotifications: null,
    PushNotifications: null,
    buttonEnabled: true,
  });

  const user = useSelector((state) => state.Auth.user);

  const GetPauses = () => {
    if (state && state.Pauses) {
      return state.Pauses;
    }
  };

  const GetExcercises = () => {
    if (state && state.Excercises) {
      return state.Excercises;
    }
  };

  const handleStateChange = async (value) => {
    let newState = { ...state };
    if (value.Pauses) {
      newState.Pauses.hours = value.Pauses;
    }
    if (value.Excercises) {
      newState.Excercises.hours = value.Excercises;
    }
    setState(newState);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userScores = await api.get(`/api/users/scores`);

      let pauses = userScores.Pauses;
      let excercises = userScores.Excercises;

      let newState = { ...state };
      newState.Pauses = { hours: [] };
      newState.Excercises = { hours: [], types: [] };

      newState.Pauses.hours = pauses ? pauses.map((element) => UTCHoursToLocal(element.pauseTime)) : [];
      newState.Excercises.types = excercises ? excercises.map((element) => element.type) : [];
      newState.Excercises.hours = excercises ? excercises.map((element) => UTCHoursToLocal(element.excerciseTime)) : [];

      newState.PushNotifications = user.pushNotification ? user.pushNotification : false;
      newState.MailNotifications = user.mailNotification ? user.mailNotification : false;
      newState.OtherMailNotifications = user.otherMailNotifications ? user.otherMailNotifications : false;

      setState(newState);
    };

    fetchData();
  }, [user]);

  const UTCHoursToLocal = (pausetime) => {
    var gmtDateTime = moment.utc(pausetime, "HH:mm");
    var local = gmtDateTime.local().format("HH:mm");
    return local;
  };

  const handleValidate = async () => {
    let Pauses = state.Pauses.hours;
    if (Pauses == null) {
      Pauses = state.Pauses;
    }

    let Excercises = {
      Hours: state.Excercises.hours,
      Types: state.Excercises.types,
    };

    setState((prevState) => ({
      ...prevState,
      buttonEnabled: false,
    }));
    console.log("state", state)
    await DeleteExistingPauses(user.id);
    await InsertPauses(Pauses, user.id);
    await DeleteExistingExcercises(user.id);
    await InsertExcercises(Excercises, user.id);
    await UpdateNotifications(user.id, state.MailNotifications, state.OtherMailNotifications, state.PushNotifications);

    /*
    let excerciseList = Excercises.Hours.map((hour, i) => ({
      id: 0,
      userid: user.id,
      excercisetime: moment(hour, "HH:mm").utc().format("HH:mm"),
      type: Excercises.Types[i],
      executed: 0,
    }));

    // Create the Pauseslist
    let Pauseslist = Pauses.map(async (pause) => ({
      id: 0,
      userid: user.id,
      pausetime: moment(pause, "HH:mm").utc().format("HH:mm"),
      executed: 0,
    }));

    await api.post("/api/users/parameters", {
      Pauses: Pauseslist,
      Excercises: excerciseList,
      mailnotification: state.MailNotifications,
      othermailnotification: state.OtherMailNotifications,
      pushnotification: state.PushNotifications,
    });
    */
    setState((prevState) => ({
      ...prevState,
      buttonEnabled: true,
    }));
    toast.success("OK", { duration: 5000 });
    handleClose();
  };

  const handleChangeNotif = (_event, { name }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  return (
    <Modal size="tiny" closeOnDimmerClick={false} open={parametersModalShow} onClose={handleClose}>
      <Modal.Header>{i18next.t("parameters.title")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form id="fatigue-form">
            <span>
              <Form.Field>
                <label>{i18next.t("common.Pause")}</label>
                <Form.Field>
                  <PausesForm
                    //@ts-ignore
                    Pauses={GetPauses()}
                    handleStateChange={handleStateChange}
                    //@ts-ignore
                    previousState={""}
                  />
                </Form.Field>
              </Form.Field>
              <span id="modal-popup"></span>
              <Form.Field>
                <label>{i18next.t("common.Exercice")}</label>
                <Form.Field>
                  <ExcercisesForm
                    //@ts-ignore
                    Excercises={GetExcercises()}
                    handleStateChange={handleStateChange}
                    //@ts-ignore
                    previousState={""}
                  />
                </Form.Field>
              </Form.Field>
              <Form.Field>
                <label>{i18next.t("common.Notification")}</label>
                <Form.Field>
                  <Radio
                    toggle
                    label={i18next.t("parameters.notifications")}
                    name="MailNotifications"
                    value="MailNotifications"
                    checked={state && state.MailNotifications}
                    onChange={handleChangeNotif}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    toggle
                    label={i18next.t("parameters.emails")}
                    name="OtherMailNotifications"
                    value="OtherMailNotifications"
                    checked={state && state.OtherMailNotifications}
                    onChange={handleChangeNotif}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    toggle
                    label={i18next.t("parameters.push")}
                    name="PushNotifications"
                    value="PushNotifications"
                    checked={state && state.PushNotifications}
                    onChange={handleChangeNotif}
                  />
                </Form.Field>
              </Form.Field>
            </span>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} negative>
          Annuler
        </Button>

        <Button
          onClick={handleValidate}
          color={state.buttonEnabled ? "blue" : "grey"}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Valider"
          enabled={state.buttonEnabled}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ParametersModal;

import { Form, Radio } from "semantic-ui-react";
import i18next from "i18next";

function VisualHealthForm({ visualHealthResults, setVisualHealthResults }) {
  const handleChange = async (_event, { name, value }) => {
    switch (name) {
      case "VisionDefect":
        setVisualHealthResults({ ...visualHealthResults, VisionDefect: value });
        break;
      case "Glasses":
        setVisualHealthResults({ ...visualHealthResults, Glasses: value });
        break;
      case "Antilumiere":
        setVisualHealthResults({ ...visualHealthResults, Antilumiere: value });
        break;
      case "Ortoptie":
        setVisualHealthResults({ ...visualHealthResults, Ortoptie: value });
        break;
      default:
        break;
    }
  };

  const handleChangeVision = async (_event, { name, value }) => {
    switch (name) {
      case "Myope":
        setVisualHealthResults({ ...visualHealthResults, Myope: !visualHealthResults.Myope, PasVisionDefect: false, UnknownVisionProblem: false });
        break;
      case "Hypermétrope":
        setVisualHealthResults({ ...visualHealthResults, Hypermetrope: !visualHealthResults.Hypermetrope, PasVisionDefect: false, UnknownVisionProblem: false });
        break;
      case "Astigmate":
        setVisualHealthResults({ ...visualHealthResults, Astigmate: !visualHealthResults.Astigmate, PasVisionDefect: false, UnknownVisionProblem: false });
        break;
      case "Presbyte":
        setVisualHealthResults({ ...visualHealthResults, Presbyte: !visualHealthResults.Presbyte, PasVisionDefect: false, UnknownVisionProblem: false });
        break;
      case "Je ne sais pas":
        setVisualHealthResults({ ...visualHealthResults, UnknownVisionProblem: !visualHealthResults.UnknownVisionProblem, PasVisionDefect: false });
        break;
      case "Je n'ai pas des problèmes de vision":
        setVisualHealthResults({ ...visualHealthResults, PasVisionDefect: !visualHealthResults.PasVisionDefect, UnknownVisionProblem: false });
        break;
      default:
        break;
    }
  };

  const VisionDefect = () => (
    <span>
      <h3> </h3>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question6")}</label>

        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer61")} name="Myope" value="Myope" checked={visualHealthResults.Myope} onChange={handleChangeVision} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer62")} name="Hypermétrope" value="Hypermétrope" checked={visualHealthResults.Hypermetrope} onChange={handleChangeVision} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer63")} name="Astigmate" value="Astigmate" checked={visualHealthResults.Astigmate} onChange={handleChangeVision} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer64")} name="Presbyte" value="Presbyte" checked={visualHealthResults.Presbyte} onChange={handleChangeVision} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer66")} name="Je ne sais pas" value="aucun" checked={visualHealthResults.UnknownVisionProblem} onChange={handleChangeVision} />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer65")}
            name="Je n'ai pas des problèmes de vision"
            value="aucun"
            checked={visualHealthResults.PasVisionDefect}
            onChange={handleChangeVision}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Glasses = () => (
    <span>
      <br></br>
      <br></br>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question7")}</label>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer71")} name="Glasses" value="oui" checked={visualHealthResults.Glasses == "oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer72")} name="Glasses" value="non" checked={visualHealthResults.Glasses == "non"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer73")}
            name="Glasses"
            value="Je ne porte pas de lunettes ni de lentilles"
            checked={visualHealthResults.Glasses == "Je ne porte pas de lunettes ni de lentilles"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Antilumiere = () => (
    <span>
      <br></br>
      <br></br>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question8")}</label>
        <Form.Field>
          <Radio toggle label={i18next.t("common.yes")} name="Antilumiere" value="Oui" checked={visualHealthResults.Antilumiere == "Oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("common.no")} name="Antilumiere" value="Non" checked={visualHealthResults.Antilumiere == "Non"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer82")}
            name="Antilumiere"
            value="je ne porte pas de lunettes"
            checked={visualHealthResults.Antilumiere == "je ne porte pas de lunettes"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer81")}
            name="Antilumiere"
            value="Je ne sais pas"
            checked={visualHealthResults.Antilumiere == "Je ne sais pas"}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Ortoptie = () => (
    <span>
      <br></br>
      <br></br>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question9")}</label>
        <Form.Field>
          <Radio toggle label={i18next.t("common.yes")} name="Ortoptie" value="Oui" checked={visualHealthResults.Ortoptie == "Oui"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("common.no")} name="Ortoptie" value="Non" checked={visualHealthResults.Ortoptie == "Non"} onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </span>
  );

  return (
    <Form id="fatigue-form">
      <h3> </h3>
      {VisionDefect()}
      {Glasses()}
      {Antilumiere()}
      {Ortoptie()}
    </Form>
  );
}

export default VisualHealthForm;

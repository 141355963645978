import React, { useState } from "react";
import { fr } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  Form, Icon} from "semantic-ui-react";

import i18next from "../i18n";

registerLocale("fr", fr);

export default ({ value, onChange }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Form.Field>
      <label style={{ display: "flex", alignItems: "center" }}>
        {i18next.t("profile.birthdate")}
        <Icon link size="mini" inverted circular color="blue" name="info" style={{ marginLeft: "5px" }} onClick={() => setVisible(!visible)} />
      </label>
      <div style={{ overflow: "hidden", transition: "max-height .5s", maxHeight: visible ? "300px" : 0, marginBottom: "5px" }}>{i18next.t("profile.dataNeeded")}</div>
      <div>
        <DatePicker selected={value} onChange={(e) => onChange({ target: { value: new Date(e), name: "birthdate" } })} locale={fr} dateFormat="P" />
      </div>
    </Form.Field>
  );
};

import { createGlobalState } from "react-context-global-state";

const initialState: {
  authenticated: boolean | null;
  onboarding: boolean;
  fullscreen: boolean;
  code: string;
  games: number;
  pauses: number;
  score: number;
} = {
  authenticated: false,
  onboarding: false,
  fullscreen: false,
  code: "",
  games: 0,
  pauses: 0,
  score: 0,
};

export type State = typeof initialState;

const { StateProvider, StateConsumer, setGlobalState, getGlobalState } = createGlobalState(initialState);

export const updateAuthenticated = (s: boolean) => {
  setGlobalState("authenticated", s);
};
export const updateOnboarding = (s: boolean) => {
  setGlobalState("onboarding", s);
};
export const updateCode = (s: string) => {
  setGlobalState("code", s);
};
export const updateFullScreen = (s: boolean) => {
  setGlobalState("fullscreen", s);
};
export const updateGames = (s: number) => {
  setGlobalState("games", s);
};
export const updatePauses = (s: number) => {
  setGlobalState("pauses", s);
};
export const updateScores = (scores: Array<any>) => {
  const date = new Date();
  const ms = date.setDate(date.getDate() - 30);
  let score = scores.reduce(function (acc: number, s: any) {
    acc += new Date(s.scoredate).getTime() >= ms ? s.score : 0;
    return acc;
  }, 0);

  setGlobalState("score", score);
};
export { StateProvider, StateConsumer, getGlobalState };

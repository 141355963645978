import React, { useState } from "react";
import { Form, Icon, Radio } from "semantic-ui-react";
import i18next from "../i18n";

export default ({ value, onChange }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Form.Field>
      <label style={{ display: "flex", alignItems: "center" }}>
        {i18next.t("profile.gender")}
        <Icon link size="mini" inverted circular color="blue" name="info" style={{ marginLeft: "5px" }} onClick={() => setVisible(!visible)} />
      </label>
      <div style={{ overflow: "hidden", transition: "max-height .5s", maxHeight: visible ? "300px" : 0, marginBottom: "5px" }}>{i18next.t("profile.dataNeeded")}</div>
      <Radio label={i18next.t("profile.man")} name="gender" value="m" checked={value === "m"} onChange={() => onChange({ target: { value: "m", name: "gender" } })} />
      <Radio
        label={i18next.t("profile.woman")}
        name="gender"
        value="f"
        checked={value === "f"}
        onChange={() => onChange({ target: { value: "f", name: "gender" } })}
        style={{ paddingLeft: "10px" }}
      />
    </Form.Field>
  );
};

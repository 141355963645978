import React, { useState, useEffect, useRef } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import { BigPlayButton, ControlBar, Player } from "video-react";
import NestedModal from "../../../components/ExcerciseModalClean";
import { updateGames, updateScores } from "../../../components/state";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { InsertScore } from "../../../helpers/GameHelper";
import i18next from "../../../i18n";
import "./gameConcentration.scss";
import imageNames from "./imageNames";
import "./video-react.css";
let images = require.context("../../../../public/images/images3d/", true);
const videos = require.context("../../../../public/videos/concentration/", true);

const Description = (props) => {
  const player = useRef();
  const history = useHistory();
  const [state, setState] = useState({
    hasBeenPlayed: false,
    userid: 0,
    videofile: "",
    imageName: "",
    imageFile: "",
    imageName1: "",
    imageFile1: "",
    imageName2: "",
    imageFile2: "",
    imageName3: "",
    imageFile3: "",
    modalOpen: false,
    nestedModalOpen: false,
    modalMessage: "",
    modalTitle: "",
    excerciseResultSucessfull: false,
  });

  const updateGameScore = async (props) => {
    let body = {
      userid: props.userid,
      date: new Date().toISOString(),
      activity: props.title,
      score: 1,
      category: props.category,
    };
    await InsertScore(body);
  };

  const handleExcerciseResult = async (result) => {
    if (result) {
      await updateGameScore(props);
    }

    setState({
      ...state,
      nestedModalOpen: false,
      modalOpen: true,
      excerciseResultSucessfull: result,
      modalMessage: result ? i18next.t("generic.excercisePassed") : i18next.t("generic.excerciseFailed"),
      modalTitle: result ? i18next.t("generic.congratulations") : i18next.t("generic.tryAgain"),
    });
  };

  const handleGameStart = async () => {
    console.log("handleGameStart", props.type);
    if (props.type == "game") {
      history.push("/iframe?game=" + props.currentgame);
    } else if (props.type == "video") {
      player.current.play();
    }
  };

  const handleGameEnd = async () => {
    if (props.interactive == "true") {
      setState({
        ...state,
        nestedModalOpen: true,
      });
    } else {
      await updateGameScore(props);
      setState({
        ...state,
        modalOpen: true,
        excerciseResultSucessfull: true,
        modalMessage: i18next.t("generic.excercisePassed"),
        modalTitle: i18next.t("generic.congratulations"),
      });
    }
  };

  const GetRandomFileName = () => {
    return btoa(imageNames[Math.floor(Math.random() * imageNames.length)]);
  };

  const GetImageName = (imageName) => {
    return images("./" + atob(imageName) + ".png");
  };

  useEffect(() => {
    const fetchData = async () => {
      let userProfile = props.user;
      let userId = userProfile.id;
      let tempState = { ...state };

      if (props.type == "video") {
        let hasBeenPlayed = await api.get(
          "/api/scores?filter=" +
            JSON.stringify({
              where: {
                activity: props.title,
                userid: userId,
                category: props.category,
              },
            })
        );

        tempState.hasBeenPlayed = hasBeenPlayed.data && hasBeenPlayed.data.length >= 1;

        if (!tempState.hasBeenPlayed) {
          tempState.videofile = props.video + ".mp4";
        } else {
          tempState.videofile = props.video + "-short.mp4";
        }

        if (props.interactive == "true") {
          let randomFileName = GetRandomFileName();
          tempState.imageName = randomFileName;
          tempState.imageFile = GetImageName(tempState.imageName);
          tempState.imageName = i18next.t("images." + atob(tempState.imageName));

          randomFileName = GetRandomFileName();
          tempState.imageName1 = randomFileName;
          tempState.imageFile1 = GetImageName(tempState.imageName1);
          tempState.imageName1 = i18next.t("images." + atob(tempState.imageName1));

          randomFileName = GetRandomFileName();
          tempState.imageName2 = randomFileName;
          tempState.imageFile2 = GetImageName(tempState.imageName2);
          tempState.imageName2 = i18next.t("images." + atob(tempState.imageName2));

          randomFileName = GetRandomFileName();
          tempState.imageName3 = randomFileName;
          tempState.imageFile3 = GetImageName(tempState.imageName3);
          tempState.imageName3 = i18next.t("images." + atob(tempState.imageName3));
        }

        player.current.video.handleEnded = async () => {
          await handleGameEnd();
        };

        player.current.handleMouseDown = (e) => {
          if (e.target.className != "video-react-icon-fullscreen video-react-fullscreen-control video-react-control video-react-button video-react-icon") {
            player.current.video.togglePlay();
          }
        };
      }

      tempState.userid = userId;
      setState(tempState);
    };
    fetchData();
  }, [props]);

  const handleClose = () => this.setState({ modalOpen: false });

  const handleNestedModalClose = () => {
    setState({
      ...state,
      nestedModalOpen: false,
    });
  };

  const GetContent = () => {
    if (props.type == "game" && props.tutorial) {
      return (
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <Player playsInline ref={player} src={videos("./" + (i18next.language.includes("fr") ? "fr" : "en") + "-" + props.tutorial)}>
            <BigPlayButton position="center" style={{ visibility: "hidden" }} />
            <ControlBar autoHide={false} />
          </Player>
        </div>
      );
    } else if (this.props.type === "game") {
      return <Image src={props.image} style={{ width: "100%", height: "100%" }} />;
    }
    if (props.type == "video") {
      return (
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <Player playsInline ref={player} src={GetVideo()}>
            <BigPlayButton position="center" style={{ visibility: "hidden" }} />
            <ControlBar autoHide={false} />
          </Player>
        </div>
      );
    }
  };

  const GetVideo = () => {
    return state?.videofile ? videos(state.videofile) : videos(props.video + ".mp4");
  };

  const GetButtons = () => {
    if (props.tutorial) {
      return ImageGameButtonsPlay(this);
    } else if (props.interactive === "false") {
      return (
        <div style={{ marginLeft: "auto", marginRight: "auto", width: "100px" }}>
          <Button type="submit" color="blue" onClick={handleGameStart}>
            {i18next.t("game.exercise")}
          </Button>
        </div>
      );
    } else {
      return ImageGameButtons(this);
    }
  };

  const ImageGameButtonsPlay = () => {
    return (
      <div className="buttons">
        <Button
          type="submit"
          color="blue"
          onClick={() => {
            if (player.current) {
              return player.current.play();
            }
          }}
        >
          {i18next.t("game.help")}
        </Button>
        <Button
          type="submit"
          color="blue"
          onClick={() => {
            handleGameStart();
          }}
        >
          {i18next.t("game.exercise")}
        </Button>
      </div>
    );
  };

  const ImageGameButtons = () => {
    return (
      <div className="buttons">
        <Button type="submit" color="blue" onClick={handleGameStart}>
          {i18next.t("game.help")}
        </Button>
        <Button
          type="submit"
          color="blue"
          onClick={() => {
            setState({ ...state, modalOpen: true });
          }}
        >
          {i18next.t("game.start")}
        </Button>
      </div>
    );
  };

  return (
    <div>
      <NestedModal open={state.nestedModalOpen} title={state.title} message={state.message} onClose={handleNestedModalClose} />
      <Modal closeOnEscape={false} closeOnDimmerClick={false} open={state.modalOpen} onClose={handleClose} basic size="fullscreen">
        <Modal.Content>
          <Image
            src={state.imageFile}
            style={{
              maxHeight: "70vh",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: "center" }}>
            {/* <RandomImageButtons /> */}
            <Button color="red" onClick={handleClose} inverted>
              <Icon name="cancel" /> {i18next.t("common.cancel")}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
      <div className="gameConcentration">
        <div className="title">{props.title}</div>
        {GetContent()}
        <div className="description">{props.description}</div>
        {GetButtons()}
      </div>
    </div>
  );
};
export default Description;

import * as Sentry from "@sentry/browser";
import "moment/locale/fr";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, Checkbox, Form } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { validateEmail } from "../../helpers/validateEmail";
import i18next from "../../i18n";
import "./login.scss";
import "react-datepicker/dist/react-datepicker.css";
import Password from "../../components/Password";
import { setUser } from "../../redux/auth/actions";

var owasp = require("owasp-password-strength-test");

const SubscriptionForm = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    firstName: "",
    email: "",
    lastName: "",
    password: "",
    gdpr: false,
    code: "",
  });

  const history = useHistory();

  const handleSubscription = async () => {
    try {
      let emailValid = process.env.REACT_APP_TEAMS === "1" || validateEmail(state.email);

      if (!emailValid) {
        toast.error(i18next.t("profile.invalidEmail"), { duration: 5000 });
        return;
      }

      // owasp.config({ allowPassphrases: false, maxLength: 64, minLength: 10, minPhraseLength: 20, minOptionalTestsToPass: 4 });
      // let passwordStrength = owasp.test(state.password);

      // if (passwordStrength.errors.length >= 1) {
      //   toast.error(i18next.t("profile.invalidPassword"), { duration: 10000 });
      //   return;
      // }

      if (!state.gdpr) {
        toast.error(i18next.t("profile.acceptCGU"), { duration: 5000 });
        return;
      }

      if (!state.firstName) return toast.error(`Missing firstname`, { duration: 5000 });
      if (!state.lastName) return toast.error(`Missing lastname`, { duration: 5000 });
      if (!state.email) return toast.error(`Missing email`, { duration: 5000 });
      if (!state.password) return toast.error(`Missing password`, { duration: 5000 });
      if (!state.code) return toast.error(`Missing code`, { duration: 5000 });

      const obj = {};
      obj.firstName = state.firstName;
      obj.lastName = state.lastName;
      obj.email = state.email;
      obj.password = state.password;
      obj.code = state.code;

      let data = await api.post("/api/users/register", obj);
      if (!data.ok) {
        toast.error(data.code, { duration: 5000 });
        return;
      }

      toast.success(i18next.t("profile.signupSuccess"), { duration: 5000 });
      api.setToken(data.token);
      dispatch(setUser(data.user));
      history.push("/");
    } catch (exception) {
      Sentry.captureException(exception);
      console.log(exception);
      toast.error(i18next.t("profile.emailOrPasswordError") + exception, { duration: 5000 });
    }
  };

  const handleInputChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  // todo
  // if (process.env.REACT_APP_TEAMS === "1") {
  //

  // }

  return (
    <Form style={{ padding: "20px" }}>
      <Form.Field>
        <label>{i18next.t("profile.firstname")}</label>
        <input name="firstName" value={state.firstName} onChange={handleInputChange} />
      </Form.Field>
      <Form.Field>
        <label>{i18next.t("profile.lastname")}</label>
        <input value={state.lastName} name="lastName" onChange={handleInputChange} />
      </Form.Field>
      <Form.Field>
        <label>{i18next.t("profile.email")}</label>
        <input value={state.email} name="email" onChange={handleInputChange} />
      </Form.Field>
      <Form.Field>
        <label>{i18next.t("profile.password")}</label>
        <Password name="password" handleChange={handleInputChange} />
      </Form.Field>
      <Form.Field>
        <label>{i18next.t("profile.code")}</label>
        <input value={state.code} name="code" onChange={handleInputChange} />
      </Form.Field>
      <div>
        <p style={{ fontSize: "smaller" }}>{i18next.t("profile.rgpd1")}</p>
        <Checkbox
          style={{ fontSize: "smaller" }}
          label={i18next.t("profile.rgpd2")}
          onChange={() => handleInputChange({ target: { name: "gdpr", value: !state.gdpr } })}
          checked={state.gdpr}
        />
        <a href={i18next.t("profile.rgpdlink")} target="_blank" rel="noreferrer">
          <p style={{ fontSize: "smaller" }}>{i18next.t("profile.rgpd3")}</p>
        </a>
        <br />
      </div>
      <Button type="submit" color="blue" onClick={() => handleSubscription()}>
        {i18next.t("profile.signup")}
      </Button>
    </Form>
  );
};

export default SubscriptionForm;

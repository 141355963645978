import React, { Component } from "react";
import i18next from "../../i18n";
import "./games.scss";
import { Button, Card, Icon, Image, Modal } from "semantic-ui-react";

let thumbnailImages = require.context("../../../public/images/images3dthumbnails/", true);
let fullImages = require.context("../../../public/images/images3d/", true);

function GetImageName(props, full) {
  let imageName;
  let name = full ? fullImages : thumbnailImages;
  try {
    imageName = name("./" + btoa(props.name) + ".png");
  } catch (ex) {
    imageName = name("./" + btoa(props.name) + ".jpg");
  }
  return imageName;
}

export default class ImageCard extends Component {
  state = {
    modalOpen: false,
    currentImage: "",
  };

  handleNestedModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleClick = () => {
    if (!this.state.modalOpen) {
      this.setState({ modalOpen: true });
    }
  };

  render() {
    return (
      <Card className="" onClick={this.handleClick} style={{ width: "160px", margin: "5px", padding: 0 }}>
        <Image src={GetImageName(this.props, false)} ui={false} style={{ width: "160px", height: "108px" }} />
        <Card.Content style={{ paddingTop: "1px", paddingBottom: "1px", paddingLeft: "5px", paddingRight: "5px" }}>
          <Modal closeOnDimmerClick={false} size="small" open={this.state.modalOpen}>
            <Modal.Header>{i18next.t(`images.${this.props.name}`)}</Modal.Header>
            <Modal.Content image>
              <div className="image">
                <Image src={GetImageName(this.props, true)} style={{ width: "100%" }} />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                inverted
                onClick={() => {
                  var link = document.createElement("a");
                  link.href = GetImageName(this.props, true);
                  link.download = GetImageName(this.props, true);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                <Icon name="checkmark" />
                {i18next.t("common.download")}
              </Button>
              <Button onClick={this.handleNestedModalClose} negative>
                {i18next.t("common.cancel")}
              </Button>
            </Modal.Actions>
          </Modal>
          <Card.Description>{i18next.t(`images.${this.props.name}`)}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

import React, { useState, useRef } from "react";

import i18next from "i18next";
import { Button, Flag, FlagNameValues, Icon, Image, Menu, Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ContactModal from "../../pages/settings/contact";
import ParametersModal from "../../pages/settings/settings";
import ProfileModal from "../../pages/settings/profil";

import "./desktop-header.scss";
import api from "../../services/api";

let images = require.context("../../../public/images/", true);

const DesktopHeader = (props) => {
  const { t } = props;
  const [parametersModalShow, setParametersModalShow] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [contactModalShow, setContactModalShow] = useState(false);
  const [menuPopup, setMenuPopup] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  const modalRef = useRef();
  const proFilmodalRef = useRef();
  const proContactmodalRef = useRef();
  const history = useHistory();

  const changeLanguage = (evt) => {
    window.localStorage.setItem("i18nextLng", evt.target.dataset.lang);
    i18next.changeLanguage(evt.target.dataset.lang);
    history.push("/");
  };

  const handleItemClick = (name) => {
    setMenuPopup(false);
    history.push(`/${name}`);
    setActiveItem(name);
  };

  const language = i18next.language.indexOf("fr") !== -1 ? "fr" : "en";
  const otherLanguage = language === "en" ? "fr" : "en";
  const flag = language === "en" ? "fr" : "us";

  return (
    <div id="outer">
      <ContactModal
        handleClose={() => setContactModalShow(false)}
        handleOpen={() => setContactModalShow(true)}
        parametersModalShow={contactModalShow}
        CloseModal={() => setContactModalShow(false)}
        ref={proContactmodalRef}
      />
      <ParametersModal handleClose={() => setParametersModalShow(false)} handleOpen={() => setParametersModalShow(true)} parametersModalShow={parametersModalShow} ref={modalRef} />
      <ProfileModal ref={proFilmodalRef} onClose={() => setProfileModalShow(false)} open={profileModalShow} />
      <Menu icon id="inner">
        <Menu.Item style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} name="home" onClick={(e) => handleItemClick("home")}>
          <Image id="vizy-head" src={images("./vizy-head.png")} />
        </Menu.Item>
        <Menu.Item name="jeux" active={activeItem === "games"} onClick={(e) => handleItemClick("games")}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon style={{ margin: 0 }} name="cube" />
            <div className="itemName">{t("common.games")}</div>
          </div>
        </Menu.Item>
        <Menu.Item name="stats" active={activeItem === "stats"} onClick={() => handleItemClick("stats")}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon style={{ margin: 0 }} name="chart bar" />
            <div className="itemName">{t("common.stats")}</div>
          </div>
        </Menu.Item>
        <Menu.Item className="MenuButtonHeader">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <ScoreStat />
          </div>
        </Menu.Item>
        <Popup
          trigger={<Button basic size="large" icon="bars" style={{ marginLeft: "auto" }} />}
          on="click"
          open={menuPopup}
          onOpen={() => setMenuPopup(true)}
          onClose={() => setMenuPopup(false)}
          position="top right"
          content={
            <div style={{ width: "200px", textAlign: "center" }}>
              <Button
                color="blue"
                content={t("profile.title")}
                onClick={() => {
                  setMenuPopup(false);
                  setProfileModalShow(true);
                }}
                style={{ width: "150px", marginBottom: "5px" }}
              />
              <Button
                color="blue"
                content={t("parameters.title")}
                onClick={() => {
                  setParametersModalShow(true);
                  setMenuPopup(false);
                }}
                style={{ width: "150px", marginBottom: "5px" }}
              />
              <Button
                color="blue"
                content={t("contact.title")}
                onClick={() => {
                  setContactModalShow(true);
                  setMenuPopup(false);
                }}
                style={{ width: "150px", marginBottom: "5px" }}
              />
              <Button color="blue" content={t("legal.title")} name="legal" onClick={() => handleItemClick("legalmentions")} style={{ width: "150px", marginBottom: "5px" }} />
              <Button
                color="blue"
                content={t("common.logout")}
                onClick={async () => {
                  await api.post("/api/users/logout");
                  window.location.replace("/");
                }}
                style={{ width: "150px", marginBottom: "5px" }}
              />
            </div>
          }
        />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Flag name={flag} data-lang={otherLanguage} onClick={changeLanguage} />
        </div>
      </Menu>
    </div>
  );
};

export default DesktopHeader;

const ScoreStat = () => {
  const value = useSelector((s) => s.currentScoreReducer.currentScore);
  const colors = ["#0ecc2b", "#ffa723", "#d82434", "#afd824", "#24d89d", "#24c8d8", "#246cd8", "#8324d8", "#d824ab", "#000000"];
  const level = value < 2000 ? 0 : Math.min(10, Math.floor((value - 2000) / 1500) + 1);
  return (
    <Popup
      position="top center"
      content={i18next.t("common.scoreExplanations")}
      trigger={
        <div className="crown" style={{ background: colors[level] }}>
          {level + 1}
        </div>
      }
    />
  );
};

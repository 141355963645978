import React, { useState, useEffect } from "react";
import IframeComm from "react-iframe-comm";
import { Modal } from "semantic-ui-react";
import NestedModal from "../../components/ExcerciseModal";
import { updateGames, updateScores } from "../../components/state";
import * as microsoftTeams from "@microsoft/teams-js";
import api from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { InsertScore } from "../../helpers/GameHelper";
import { apiURL } from "../../config";

const Iframe = () => {
  const history = useHistory();
  const user = useSelector((state) => state.Auth.user);
  const [state, setState] = useState({
    nestedModalOpen: false,
    modalTitle: null,
    modalMessage: null,
    ModalOpen: true,
    excerciseResultSucessfull: false,
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const currentGame = queryParameters.get("game");

  const handleNestedModalClose = () => {
    setState({
      ...state,
      nestedModalOpen: false,
    });
    history.push("/home");
    //window.location.href = "/#/home";
  };

  const OpenPopup = (title, message, success) => {
    setState({
      ...state,
      nestedModalOpen: true,
      modalTitle: title,
      modalMessage: message,
      excerciseResultSucessfull: success,
    });
  };

  const handleGameEnd = async (points) => {
    const userData = await api.get("/api/users/scores");

    await InsertScore(user.id, "Concentration", points, currentGame, "Game", "");

    const games = userData.ExcercisesScore;
    updateScores(userData.Scores);
    if (games) {
      updateGames(games);
    }
    OpenPopup("Bravo!", "Vous gagnez " + points + " points.", true);
  };

  const onReceiveMessage = (event) => {
    if (event && event.data && typeof event.data === "string" && event.data.includes("score")) {
      const parsedData = JSON.parse(event.data);
      handleGameEnd(parsedData.score);
    }
  };

  const onReady = () => {
    console.log("onReady");
  };

  const attributes = {
    src: apiURL + currentGame,
    width: "100%",
    height: "100%",
    frameBorder: 1,
  };

  const postMessageData = { level: 1 };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      onClose={() => {
        setState({
          ...state,
          ModalOpen: false,
        });
        if (process.env.REACT_APP_TEAMS === "1") {
          microsoftTeams.initialize();
          microsoftTeams.executeDeepLink(`https://teams.microsoft.com/l/entity/${process.env.REACT_APP_APP_ID}/activities`);
        } else {
          history.push("/games");
        }
      }}
      size={"fullscreen"}
      basic
      open={state.ModalOpen}
      closeIcon
    >
      <Modal.Content style={{ height: "85vh" }}>
        {NestedModal({
          open: state.nestedModalOpen,
          title: state.modalTitle,
          message: state.modalMessage,
          onClose: handleNestedModalClose,
        })}
        <IframeComm attributes={attributes} postMessageData={postMessageData} handleReady={onReady} handleReceiveMessage={onReceiveMessage} />
      </Modal.Content>
    </Modal>
  );
};

export default Iframe;

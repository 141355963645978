import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { Image } from "semantic-ui-react";

//@ts-ignore
let otherimages = require.context("../../public/images/", true);

const NestedModal = ({ open, title, message, onClose }) => {
  return (
    <Modal size="small" open={open}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content image>
        <div className="image">
          <Image src={otherimages("./vizy-head.png")} style={{ width: "10vh" }} />
        </div>
        <Modal.Description>
          <h3>{message}</h3>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" inverted onClick={onClose}>
          <Icon name="checkmark" /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default NestedModal;

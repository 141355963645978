import React, { useState } from "react";
import { Icon } from "semantic-ui-react";

const Password = ({ name, handleChange }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown((prevPasswordShown) => !prevPasswordShown);
  };

  const iconStyles = {
    position: "absolute",
    margin: "0.3rem 0 0 -3rem",
    cursor: "pointer",
  };

  return (
    <div className="relative">
      <input
        name={name}
        type={passwordShown ? "text" : "password"}
        className="w-full pr-10 py-2 border rounded"
        onChange={handleChange}
      />
      <span style={iconStyles} onClick={togglePassword}>
        <Icon name="eye" circular link />
      </span>
    </div>
  );
};

export default Password;
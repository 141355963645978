import React from "react";
import { Form } from "semantic-ui-react";
import "./onboarding.scss";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment, { Moment } from "moment";

interface IProps {
  handleStateChange: (value: any) => any;
  Pauses: { number: number; hours: string[] } | undefined;
  previousState: any;
}
export default class PausesForm extends React.Component<IProps> {
  state = {
    Pauses: Array<string>()
  };

  handleChange = async (name: any, newValue: Moment) => {
    let state = { ...this.state };
    let index = state.Pauses.indexOf(name);

    if (index !== -1) {
      if (newValue)
        state.Pauses[index] = newValue.format("HH:mm");
      else
        state.Pauses = state.Pauses.filter((item, idx) => idx !== index);
      await this.props.handleStateChange(state);
    }
  };

  componentDidMount() {
    console.log('pc props', this.props);
    console.log('pc state', this.state);
    if (this.props.Pauses) {
      let state = { ...this.state };
      state.Pauses = this.props.Pauses.hours;
      this.setState(state);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    console.log('pc cwrp', nextProps);
    this.state.Pauses = nextProps.Pauses.hours;
  }
  
  render() {
    console.log('render pc', this.state);

    let context = this;
    return (
      <Form id="fatigue-form">
        <Form.Field>
          {this.state.Pauses.sort().map(function (name, index) {
            return (
              <div key={name}>
                <TimePicker
                  onChange={newValue => context.handleChange(name, newValue)}
                  defaultValue={moment(context.state.Pauses[index], "HH:mm")}
                  showSecond={false}
                />
              </div>
            );
          })}
        </Form.Field>
      </Form>
    );
  }
  handleAdd = async () => {
    let state = { ...this.state };
    state.Pauses.push("");
    await this.props.handleStateChange(state);
    this.setState(state);
  };
  handleRemove = async () => {
    let state = { ...this.state };
    state.Pauses.pop();
    await this.props.handleStateChange(state);
    this.setState(state);
  };
}

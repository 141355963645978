import * as Sentry from "@sentry/browser";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import Device from "react-device";
import { isMobile } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Grid, Modal } from "semantic-ui-react";
import { updateGames, updatePauses, updateScores } from "../../components/state";
import { GetNextExcercise } from "../../helpers/ProgramHelper";
import { GetScores } from "../stats/CumulativeChart";
import { useHistory, Redirect } from "react-router-dom";
import { Actions } from "./actions";
import { CountDown } from "./countdown";
import "./home.scss";
import { DailyProgram } from "./program";
import { Stats } from "./stats";
import { About } from "./about";
import i18next from "../../i18n";
import api from "../../services/api";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { set } from "date-fns";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    userid: 0,
    code: "",
    isHidden: false,
    Percentage: 10,
    Fatigue: null,
    Program: {
      Tonus: { ToDo: 0, Done: 0, Percentage: 0 },
      Detente: { ToDo: 0, Done: 0, Percentage: 0 },
      Concentration: { ToDo: 0, Done: 0, Percentage: 0 },
      Pauses: { ToDo: 0, Done: 0, Percentage: 0 },
    },
    loading: true,
    Pauses: {},
    Scores: {},
  });

  const [isFull, setIsFull] = useState(false);

  const history = useHistory(); // Get the history object
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const load = async () => {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user.id });
        // scope.clear();
      });

      //Sentry.init({ dsn: "https://1fe2ab7d666a4097b682931fab9d87d0@sentry.io/1856630" });
      //await StoreFireBaseToken(user.id);
      if (!user.onboarding) {
        return;
      }
      const userData = await api.get("/api/users/scores");
      let PausesScore = userData.PausesScore;
      let GamesScore = userData.ExcercisesScore;
      let Fatigue = await GetUserFatigue(user.id);
      let { ProgramTonus, ProgramDetente, ProgramConcetration } = {
        ProgramTonus: userData.Program.Tonus,
        ProgramDetente: userData.Program.Detente,
        ProgramConcetration: userData.Program.Concentration,
      };

      //@ts-ignore
      if (PausesScore) {
        //@ts-ignore
        updatePauses(PausesScore.Percentage);
      }
      updateGames(GamesScore);
      updateScores(userData.Scores);

      state.Scores = await GetScores(user.id, user.code);

      state.userid = user.id;
      state.code = user.code;
      state.Program.Pauses = PausesScore;
      state.Program.Tonus = ProgramTonus;
      state.Program.Detente = ProgramDetente;
      state.Program.Concentration = ProgramConcetration;
      state.Fatigue = Fatigue;
      state.Percentage = Fatigue.score;
      state.loading = false;

      setState(state);
      setLoading(false);
    };
    load();
  }, [user]);

  // const Train = async (type) => {
  //   let RandomGame = await GetNextExcercise(this.state.userid, type);

  //   if (RandomGame[0]) {
  //     window.location.href = "/#/description?game=" + RandomGame[0] + "&id=" + RandomGame[1];
  //   } else {
  //     toast.success(i18next.t("dashboard.program.wellDone"), { duration: 5000 });
  //   }
  // };

  const onChange = (deviceInfo) => {
    localStorage.setItem("device", JSON.stringify(deviceInfo));
  };

  const GetUserFatigue = async (userId) => {
    let response = await api.get("/api/fatigabilities");
    if (response.ok) {
      return response.fatigabilities[0];
    }
    return 0;
  };

  async function StoreFireBaseToken(userId) {
    if (localStorage.getItem("firebase-token")) {
      const response = await api.get("/api/devicestokens", { token: localStorage.getItem("firebase-token") });
      const tokens = response.devicesTokens;
      if (tokens.length == 0) {
        console.log("storing token into database");
        await api.post("/api/devicestokens", {
          device: localStorage.getItem("device"),
          token: localStorage.getItem("firebase-token"),
          lastconnection: new Date(),
        });
      } else {
        console.log("updating date, token already exists");
        //@ts-ignore
        tokens.forEach(async (token) => {
          await api.patch(`/api/devicestokens/${token.id}`, {
            lastconnection: new Date(),
            device: localStorage.getItem("device"),
          });
        });
      }
    } else {
      await api.post("/api/devicestokens", { token: "", device: localStorage.getItem("device"), lastconnection: new Date() });
    }
  }

  if (!user.onboarding) {
    history.push("/onboarding");
  }
  return (
    <div id="dashboard-container">
      <Device onChange={onChange} />
      <Grid stackable columns={2}>
        <Grid.Column width={8}>
          <Grid.Row>
            <DailyProgram state={state} setState={setState} isFull={isFull} setIsFull={setIsFull} />
          </Grid.Row>
          <Grid.Row>
            <Actions state={state} />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>
            <Stats state={state} setState={setState} />
          </Grid.Row>
          <Grid.Row>
            <About state={state} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <GetPausesScreen context={state} setState={setState} isFull={isFull} setIsFull={setIsFull} />
    </div>
  );
};

export default Dashboard;

const GetPausesScreen = ({ context, setState, isFull, setIsFull }) => {
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (isFull) handle.enter();
    else {
      handle.exit();
    }
  }, [isFull]);

  if (!isMobile) {
    return (
      <FullScreen handle={handle} onChange={(v) => setIsFull(v)}>
        <div className="full-screenable-node" style={{ color: "white" }}>
          {CountDown(context, setState, isFull, setIsFull)}
        </div>
      </FullScreen>
    );
  } else {
    return (
      <Modal open={isFull} basic size="small">
        <Modal.Content>{CountDown(context)}</Modal.Content>
      </Modal>
    );
  }
};

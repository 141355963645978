import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, Form } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { setUser } from "../../redux/auth/actions";
import i18next from "../../i18n";
import "./login.scss";

import api from "../../services/api";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      let response = await api.post("/api/users/login", { email: username, password: password });

      dispatch(setUser(response.user));
      api.setToken(response.token);
      // window.location.href = "/#/home";
    } catch (exception) {
      console.log(`exception`, exception);
      toast.error(i18next.t("profile.loginError"), { duration: 5000 });
    }
  };

  const user = useSelector((state) => state.Auth.user);
  if (user) return <Redirect to="/" />;

  return (
    <Form style={{ padding: "20px" }}>
      <Form.Field>
        <label>{i18next.t("profile.email")}</label>
        <input value={username} placeholder="Email" onChange={(e) => setUsername(e.target.value)} style={{ fontSize: "18px" }} />
      </Form.Field>
      <Form.Field>
        <label>{i18next.t("profile.password")}</label>
        <input value={password} placeholder="Mot de passe" type="password" onChange={(e) => setPassword(e.target.value)} style={{ fontSize: "18px" }} />
      </Form.Field>
      <Button type="submit" color="blue" onClick={handleLogin}>
        {i18next.t("profile.login")}
      </Button>
    </Form>
  );
};

export default LoginForm;

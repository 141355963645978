import axios from "./axios";
import moment from "moment";
import GamesDefinition from "../data/game-definition";
import api from "../services/api";
export async function GetNextExcercise(userid: number, type: string) {
  let Excercises = await api.get("/api/exercises?filter=" + JSON.stringify({ where: { userid, type }, order: ["ExcerciseTime", "ASC"] }));
  console.log("Excercises", Excercises);
  let result = GetCurrentExcercise();
  //If there is no result found then we return a previous one
  if (!result[0]) {
    result = GetPreviousExcercise();
    return result;
  }
  return result;

  function GetCurrentExcercise() {
    let result = "";
    let id = 0;
    let found = false;
    Excercises.forEach((element: any) => {
      if (found) return;
      if (!element.dateexecuted || getCurrentDateTruncateTime().isAfter(dateExecuted(element))) {
        // if (isCurrentExercise(element)) {
        let Games = Object.keys(GamesDefinition).filter((x) => GamesDefinition[x].category == element.type);
        let count = Games.length;
        let randomGame = Math.floor(Math.random() * count + 1) - 1;
        result = Games[randomGame];
        id = element.id;
        found = true;
        return;
      }
      //}
    });
    return [result, id];
  }
  function isCurrentExercise(element: any) {
    return currentHour() == ExcerciseHour(element) && CurrentMinutes() >= ExcerciseMinutes(element);
  }
  function GetPreviousExcercise() {
    let result = "";
    let id = 0;
    let found = false;
    Excercises.data.forEach((element: any) => {
      const currentDate = moment().startOf("day");
      const executedDate = moment(Date.parse(element.dateexecuted)).startOf("day");

      if (!element.dateexecuted) {
        GetRandomGameByType(element);
      } else if (currentDate.isAfter(executedDate)) {
        GetRandomGameByType(element);
      }
      return;
    });
    if (!result) {
      window.location.href = "/#/games";
    }
    return [result, id];

    function GetRandomGameByType(element: any) {
      let Games = Object.keys(GamesDefinition).filter((x) => GamesDefinition[x].category == element.type);
      if (Games.length >= 1 && !found) {
        let count = Games.length;
        let randomGame = Math.floor(Math.random() * count + 1) - 1;
        result = Games[randomGame];
        id = element.id;
        found = true;
      }
    }
  }

  function CurrentMinutes() {
    let res = moment().utc().minutes();
    return res;
  }

  function ExcerciseMinutes(element: any) {
    let res = GetUtcMinutes(element).pauseMinutes;
    return res;
  }

  function ExcerciseHour(element: any) {
    let res = GetUtcHours(element).pauseHour;
    return res;
  }

  function currentHour() {
    let res = moment().utc().hours();
    return res;
  }
}

function dateExecuted(element: any) {
  return moment(element.dateexecuted, "MM-DD-YYYY").startOf("day");
}

function getCurrentDateTruncateTime() {
  return moment().startOf("day");
}
function GetUtcHours(element: any) {
  let currentHour = moment().utc().hours();
  let pauseHour = moment(element.excercisetime, "HH:mm").hours();
  return {
    currentHour,
    pauseHour,
  };
}

function GetUtcMinutes(element: any) {
  let currentMinutes = moment().utc().minutes();
  let pauseMinutes = moment(element.excercisetime, "HH:mm").minutes();
  return {
    currentMinutes,
    pauseMinutes,
  };
}

const GamesDefinition: { [key: string]: any } = {
  // MemoryFun: {
  //   title: "Memory Fun",
  //   tutorial: "tutoriel-memofun.mp4",
  //   key: "memofun",
  //   image: "memofun.jpg",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  // MemoryBox: {
  //   title: "Memory Box",
  //   tutorial: "tutorial-memorybox.mp4",
  //   key: "memorybox",
  //   image: "MemoryBox.png",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  // ColorDrops: {
  //   title: "Color Drops",
  //   tutorial: "tutorial-color-drops.mp4",
  //   key: "colorrun",
  //   image: "colordrops.png",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  // FlakesRace: {
  //   title: "Flakes Race",
  //   tutorial: "tutorial-flakesrace.mp4",
  //   key: "flakesrace",
  //   image: "flakesrace.png",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  // FishGame: {
  //   title: "Fish Game",
  //   tutorial: "tutorial-fishgame.mp4",
  //   key: "fishgame",
  //   image: "fishgame.png",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  // NumberQuiz: {
  //   title: "Number Quiz",
  //   tutorial: "tutorial-numberquiz.mp4",
  //   key: "numberquiz",
  //   image: "numberquiz.png",
  //   video: "",
  //   category: "Concentration",
  //   type: "game",
  //   points: "100",
  //   interactive: "false",
  // },
  Conv3D: {
    title: "Convergence 3D",
    key: "Conv3D",
    image: "Conv3D.png",
    category: "Tonus",
    type: "video",
    points: "400",
    interactive: "true",
    level: 3,
    time: "1'",
  },
  Div3D: {
    title: "Divergence 3D",
    key: "Div3D",
    image: "Div3D.jpg",
    category: "Detente",
    interactive: "true",
    type: "video",
    points: "400",
    level: 3,
    time: "2'",
  },
  Horloge: {
    title: "L'Horloge",
    key: "Horloge",
    image: "Horloge.jpg",
    category: "Detente",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "1'30",
  },
  LaCroix: {
    title: "La Croix",
    key: "LaCroix",
    image: "LaCroix.jpg",
    category: "Detente",
    type: "video",
    points: "100",
    interactive: "false",
    level: 1,
    time: "1'30",
  },
  LeCrayon: {
    title: "Le Crayon",
    key: "LeCrayon",
    image: "LeCrayon.png",
    category: "Tonus",
    type: "video",
    points: "100",
    interactive: "false",
    level: 2,
    time: "30''",
  },
  LeDemiCercleDroite: {
    title: "Demi-cercle droit",
    key: "LeDemiCercleDroite",
    image: "LeDemiCercleDroite.png",
    category: "Detente",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "1'",
  },
  Palming: {
    title: "Palming",
    key: "Palming",
    image: "Palming.jpg",
    category: "Detente",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "1'",
  },
  PingPongH: {
    title: "Ping-Pong H",
    key: "PingPongH",
    image: "PingPongH.png",
    category: "Tonus",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "30'",
  },
  PingPongV: {
    title: "Ping-Pong V",
    key: "PingPongV",
    image: "PingPongV.png",
    category: "Tonus",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "30'",
  },
  LoinPres: {
    title: "Loin-Près",
    key: "LoinPres",
    image: "LoinPres.jpg",
    category: "Tonus",
    type: "video",
    points: "100",
    interactive: "false",
    level: 2,
    time: "1'",
  },
  LeV: {
    title: "Le V",
    key: "LeV",
    image: "LeV.png",
    category: "Tonus",
    type: "video",
    points: "200",
    interactive: "false",
    level: 2,
    time: "30''",
  },
  SaccadesDynamiquesHorizontales: {
    title: "Saccades dynamiques Horizontales",
    key: "SaccadesDynamiquesHorizontales",
    image: "SaccadesDynamiquesHorizontales.jpg",
    category: "Tonus",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "1 min",
  },
  LeX: {
    title: "Le X",
    key: "LeX",
    image: "PrevX.jpg",
    category: "Detente",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "1'",
  },
  Infini: {
    title: "L'Infini",
    key: "Infini",
    image: "PrevInfini.jpg",
    category: "Detente",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "30''",
  },
  CouHorizontal: {
    title: "Cou Horizontal",
    key: "CouHorizontal",
    image: "CouHorizontal.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "45''",
  },
  CouAvantArriere: {
    title: "Cou Avant/Arrière",
    key: "CouAvantArriere",
    image: "CouAvantArriere.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "45''",
  },
  CouDiagonal: {
    title: "Cou Diagonal",
    key: "CouDiagonal",
    image: "CouDiagonal.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "45''",
  },
  DosEtirements: {
    title: "Dos étirements",
    key: "DosEtirements",
    image: "DosEtirements.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 2,
    time: "45''",
  },
  EpaulesEtirementsHautBas: {
    title: "Epaules étirements Haut/Bas",
    key: "EpaulesEtirementsHautBas",
    image: "EpaulesEtirementsHautBas.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 1,
    time: "60''",
  },
  DosJambesEtirements: {
    title: "Dos/Jambes étirements",
    key: "DosJambesEtirements",
    image: "DosJambesEtirements.jpg",
    category: "Concentration",
    type: "video",
    points: "200",
    interactive: "false",
    level: 2,
    time: "45''",
  },
};
export default GamesDefinition;

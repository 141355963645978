import i18next from "i18next";
import React from "react";
import toast from "react-hot-toast";
import { Dropdown, Form, Radio, Table } from "semantic-ui-react";
import "./onboarding.scss";
//@ts-ignore
let images = require.context("../../../public/images/", true);
interface IProps {
  handleStateChange: (name: string, value: any) => any;
  previousState: any;
}

export default class ErgonomieQuestionsForm extends React.Component<IProps> {
  Options = [
    {
      key: "Moins de 13 pouces",
      text: `Moins de 13 ${i18next.t("common.inches")}`,
      value: "Moins de 13 pouces",
    },
    { key: "13 pouces", text: `13 ${i18next.t("common.inches")}`, value: "13 pouces" },
    { key: "14 pouces", text: `14 ${i18next.t("common.inches")}`, value: "14 pouces" },
    { key: "15 pouces", text: `15 ${i18next.t("common.inches")}`, value: "15 pouces" },
    { key: "16 pouces", text: `16 ${i18next.t("common.inches")}`, value: "16 pouces" },
    { key: "17 pouces", text: `17 ${i18next.t("common.inches")}`, value: "17 pouces" },
    { key: "18 pouces", text: `18 ${i18next.t("common.inches")}`, value: "18 pouces" },
    { key: "19 pouces", text: `19 ${i18next.t("common.inches")}`, value: "19 pouces" },
    { key: "20 pouces", text: `20 ${i18next.t("common.inches")}`, value: "20 pouces" },
    { key: "21 pouces", text: `21 ${i18next.t("common.inches")}`, value: "21 pouces" },
    {
      key: "Plus de 21 pouces",
      text: `Plus de 21 ${i18next.t("common.inches")}`,
      value: "Plus de 21 pouces",
    },
  ];

  state = {
    ErgonomieQuestions: {
      Position: "Face à moi",
      MultipleScreens: false,
      DominantEye: "Oeil gauche",
      ScreenSize: "Moins de 13 pouces",

      NaturalLight: "",
      ArtificialLight: "",
      WebCam: false,
      GoodDistance: false,
      GoodHeight: false,
    },
    WebCamHeight: {
      GoodHeight: false,
    },
    WebCamDistance: {
      GoodDistance: false,
      Distance: "",
    },
  };

  componentDidMount() {
    if (this.props.previousState) {
      let newState = { ...this.state };
      newState = this.props.previousState;
      this.setState(newState);
    }
  }
  handleDropDownChange = async (e: any, { value }: any) => {
    let state = { ...this.state };
    state.ErgonomieQuestions.ScreenSize = value;
    await this.props.handleStateChange("ErgonomieQuestions", state);
    this.setState(state);
  };
  handleChange = async (_event: any, { name, value }: any) => {
    let state = { ...this.state };
    switch (name) {
      case "Position": {
        state.ErgonomieQuestions.Position = value;
        break;
      }
      case "MultipleScreens": {
        state.ErgonomieQuestions.MultipleScreens = !state.ErgonomieQuestions.MultipleScreens;
        break;
      }
      case "ScreenSize": {
        state.ErgonomieQuestions.ScreenSize = value;
        break;
      }
      case "DominantEye": {
        state.ErgonomieQuestions.DominantEye = value;
        break;
      }

      case "NaturalLight": {
        state.ErgonomieQuestions.NaturalLight = value;
        break;
      }
      case "ArtificialLight": {
        state.ErgonomieQuestions.ArtificialLight = value;
        break;
      }
      case "GoodHeight": {
        state.ErgonomieQuestions.GoodHeight = !state.ErgonomieQuestions.GoodHeight;
        break;
      }
      case "GoodDistance": {
        state.ErgonomieQuestions.GoodDistance = !state.ErgonomieQuestions.GoodDistance;
        break;
      }
      case "WebCam": {
        state.ErgonomieQuestions.GoodDistance = false;
        state.ErgonomieQuestions.GoodHeight = false;
        state.WebCamDistance.GoodDistance = false;
        state.WebCamHeight.GoodHeight = false;
        if (value === "Oui") {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then(function (stream) {
              state.ErgonomieQuestions.WebCam = true;
            })
            .catch(function (e) {
              state.ErgonomieQuestions.WebCam = false;
              toast.error(i18next.t("common.webcamPermission"), { duration: 10000 });
            });
        }
        state.ErgonomieQuestions.WebCam = !state.ErgonomieQuestions.WebCam;
        break;
      }
    }
    await this.props.handleStateChange("ErgonomieQuestions", state);
    this.setState(state);
  };
  render() {
    return (
      <Form id="fatigue-form">
        <h3></h3>
        {this.Position()}
        {window.innerWidth > 500 && this.WebCam()}
        {window.innerWidth <= 500 && this.WebCamQuestions()}
      </Form>
    );
  }
  private WebCam = () => {
    return (
      <span>
        <br />
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question7")}</label>
          <p dangerouslySetInnerHTML={{ __html: i18next.t("tests.ergonomics.question7help") }} />
          <Form.Field>
            <Radio toggle label={i18next.t("common.yes")} name="WebCam" value="Oui" checked={this.state.ErgonomieQuestions.WebCam} onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <Radio toggle label={i18next.t("common.no")} name="WebCam" value="Non" checked={!this.state.ErgonomieQuestions.WebCam} onChange={this.handleChange} />
          </Form.Field>
        </Form.Field>
        {!this.state.ErgonomieQuestions.WebCam && this.WebCamQuestions()}
      </span>
    );
  };
  private WebCamQuestions() {
    return (
      <span>
        <h3></h3>
        <br />
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question8")}</label>
          <p dangerouslySetInnerHTML={{ __html: i18next.t("tests.ergonomics.question8help") }} />
          <Form.Field>
            <Radio toggle label={i18next.t("common.yes")} name="GoodHeight" value="Oui" checked={this.state.ErgonomieQuestions.GoodHeight} onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <Radio toggle label={i18next.t("common.no")} name="GoodHeight" value="Non" checked={!this.state.ErgonomieQuestions.GoodHeight} onChange={this.handleChange} />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question9")}</label>
          <p dangerouslySetInnerHTML={{ __html: i18next.t("tests.ergonomics.question9help") }} />
          <div style={{ maxWidth: "250px", fontSize: "12px", margin: "1em 0" }}>
            <Table celled compact striped size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{i18next.t("tests.ergonomics.question9table1")}</Table.HeaderCell>
                  <Table.HeaderCell>{i18next.t("tests.ergonomics.question9table2")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>&lt;50</Table.Cell>
                  <Table.Cell collapsing></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>50</Table.Cell>
                  <Table.Cell collapsing>33</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>54</Table.Cell>
                  <Table.Cell collapsing>36</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>57</Table.Cell>
                  <Table.Cell collapsing>38</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>62</Table.Cell>
                  <Table.Cell collapsing>41</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>65</Table.Cell>
                  <Table.Cell collapsing>43</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>69</Table.Cell>
                  <Table.Cell collapsing>46</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>72</Table.Cell>
                  <Table.Cell collapsing>48</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>77</Table.Cell>
                  <Table.Cell collapsing>51</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>80</Table.Cell>
                  <Table.Cell collapsing>53</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <Form.Field>
            <Radio toggle label={i18next.t("common.yes")} name="GoodDistance" value="Oui" checked={this.state.ErgonomieQuestions.GoodDistance} onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <Radio toggle label={i18next.t("common.no")} name="GoodDistance" value="Non" checked={!this.state.ErgonomieQuestions.GoodDistance} onChange={this.handleChange} />
          </Form.Field>
        </Form.Field>
      </span>
    );
  }
  private Position() {
    return (
      <span>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question1")}</label>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer11")}
              name="Position"
              value="Face à moi"
              checked={this.state.ErgonomieQuestions.Position == "Face à moi"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer12")}
              name="Position"
              value="Sur le coté"
              checked={this.state.ErgonomieQuestions.Position == "Sur le coté"}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question2")}</label>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("common.yes")}
              name="MultipleScreens"
              value="oui"
              checked={this.state.ErgonomieQuestions.MultipleScreens == true}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("common.no")}
              name="MultipleScreens"
              value="Non"
              checked={this.state.ErgonomieQuestions.MultipleScreens == false}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question3")}</label>
          <div dangerouslySetInnerHTML={{ __html: i18next.t("tests.ergonomics.question3help") }} />
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer31")}
              name="DominantEye"
              value="Oeil droit"
              checked={this.state.ErgonomieQuestions.DominantEye == "Oeil droit"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer32")}
              name="DominantEye"
              value="Oeil gauche"
              checked={this.state.ErgonomieQuestions.DominantEye == "Oeil gauche"}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question4")}</label>
          <Form.Field>
            <Dropdown
              name="ScreenSize"
              selection
              upward
              options={this.Options}
              text={this.state.ErgonomieQuestions.ScreenSize}
              onChange={this.handleDropDownChange}
              value={this.state.ErgonomieQuestions.ScreenSize}
              style={{ maxWidth: "25vh" }}
            />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question5")}</label>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer51")}
              name="NaturalLight"
              value="De coté"
              checked={this.state.ErgonomieQuestions.NaturalLight == "De coté"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer52")}
              name="NaturalLight"
              value="De devant"
              checked={this.state.ErgonomieQuestions.NaturalLight == "De devant"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer53")}
              name="NaturalLight"
              value="De derrière"
              checked={this.state.ErgonomieQuestions.NaturalLight == "De derrière"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer54")}
              name="NaturalLight"
              value="Il n'y en a pas"
              checked={this.state.ErgonomieQuestions.NaturalLight == "Il n'y en a pas"}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Field>
        <h3></h3>
        <Form.Field>
          <label>{i18next.t("tests.ergonomics.question6")}</label>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer61")}
              name="ArtificialLight"
              value="Plafonnier"
              checked={this.state.ErgonomieQuestions.ArtificialLight == "Plafonnier"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer62")}
              name="ArtificialLight"
              value="Lampe d'appoint"
              checked={this.state.ErgonomieQuestions.ArtificialLight == "Lampe d'appoint"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer63")}
              name="ArtificialLight"
              value="Halogène"
              checked={this.state.ErgonomieQuestions.ArtificialLight == "Halogène"}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t("tests.ergonomics.answer64")}
              name="ArtificialLight"
              value="Il n'y en a pas"
              checked={this.state.ErgonomieQuestions.ArtificialLight == "Il n'y en a pas"}
              onChange={this.handleChange}
            />
          </Form.Field>
        </Form.Field>
      </span>
    );
  }
}

import axios from 'axios';
import * as Sentry from "@sentry/browser";
import { apiURL } from '../config';
const baseUrl = apiURL;

const Axios = axios.create({
  baseURL: baseUrl,
  timeout: 90000,
  withCredentials: false,
});

Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    Sentry.captureException(error);
    Promise.reject(error)
  },
);
export default Axios;

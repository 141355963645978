import React from "react";
import { Form, Checkbox, Radio } from "semantic-ui-react";
import "./onboarding.scss";
import i18next from "i18next";

function EyesForm({ eyesResults, setEyesResults }) {


  const handleChange = async (_event, { name, value }) => {
    switch (name) {
      case "Frequency":
        setEyesResults({ ...eyesResults, Frequency: value });
        break;
      case "Headache": {
        setEyesResults({ ...eyesResults, Headache: !eyesResults.Headache });
        break;
      }
      case "Larmoiements": {
        setEyesResults({ ...eyesResults, Larmoiements: !eyesResults.Larmoiements });
        break;
      }
      case "Sechese": {
        setEyesResults({ ...eyesResults, Sechese: !eyesResults.Sechese });
        break;
      }
      case "Picotements": {
        setEyesResults({ ...eyesResults, Picotements: !eyesResults.Picotements });
        break;
      }
      case "VisionFloue": {
        setEyesResults({ ...eyesResults, VisionFloue: !eyesResults.VisionFloue });
        break;
      }
      case "VisionDouble": {
        setEyesResults({ ...eyesResults, VisionDouble: !eyesResults.VisionDouble });
        break;
      }
      case "Migraines": {
        setEyesResults({ ...eyesResults, Migraines: !eyesResults.Migraines });
        break;
      }
      case "Concentration": {
        setEyesResults({ ...eyesResults, Concentration: !eyesResults.Concentration });
        break;
      }
      case "VisualFatigue": {
        setEyesResults({ ...eyesResults, VisualFatigue: !eyesResults.VisualFatigue });
        break;
      }
      case "OcularDolor": {
        setEyesResults({ ...eyesResults, OcularDolor: !eyesResults.OcularDolor });
        break;
      }
      case "asleepDifficulty": {
        setEyesResults({ ...eyesResults, asleepDifficulty: !eyesResults.asleepDifficulty });
        break;
      }
      case "otherDisorders": {
        setEyesResults({ ...eyesResults, otherDisorders: !eyesResults.otherDisorders });
        break;
      }
      default:
        break;
    }
  };

  const VisualProblems = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question2")}</label>
        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.1")} name="Headache" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.2")} name="Larmoiements" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.3")} name="Sechese" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.4")} name="Picotements" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.5")} name="VisionFloue" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.6")} name="VisionDouble" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.7")} name="Migraines" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.8")} name="Concentration" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.9")} name="VisualFatigue" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.10")} name="OcularDolor" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.11")} name="asleepDifficulty" onChange={handleChange} />
        </Form.Field>

        <Form.Field>
          <Checkbox toggle label={i18next.t("tests.vitality.answer2.12")} name="otherDisorders" onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </span>
  );

  const Fatigue = () => (
    <div>
      <p style={{ maxWidth: "60em" }}>{i18next.t("tests.vitality.intro1")}</p>
      <p style={{ maxWidth: "60em" }}>{i18next.t("tests.vitality.intro2")}</p>
      <p style={{ maxWidth: "60em" }}>{i18next.t("tests.vitality.intro3")}</p>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question1")}</label>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer11")} name="Frequency" value="Tous les ans" checked={eyesResults.Frequency === "Tous les ans"} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer12")}
            name="Frequency"
            value="Entre 2 et 4 ans"
            checked={eyesResults.Frequency == "Entre 2 et 4 ans"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t("tests.vitality.answer13")}
            name="Frequency"
            value="Au-delà de 5 ans"
            checked={eyesResults.Frequency == "Au-delà de 5 ans"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio toggle label={i18next.t("tests.vitality.answer14")} name="Frequency" value="Jamais" checked={eyesResults.Frequency == "Jamais"} onChange={handleChange} />
        </Form.Field>
      </Form.Field>
    </div>
  );

  return (
    <Form id="fatigue-form">
      <h3> </h3>
      {Fatigue()}
      {VisualProblems()}
    </Form>
  );
}

export default EyesForm;

import React, { Component } from "react";
import { StateConsumerType } from "react-context-global-state";
import { isMobile } from "react-device-detect";
import { State, StateConsumer } from "../state";
import DesktopHeader from "./DesktopHeader";
import "./header.scss";
import MobileHeader from "./MobileHeader";
import { withTranslation } from "react-i18next";
//@ts-ignore
let images = require.context("../../../public/images/", true);

const DesktopHeaderTranslated = withTranslation()(DesktopHeader);

export default class Header extends Component {
  render() {
    const AuthenticationStateConsumer = StateConsumer as StateConsumerType<State, "fullscreen">;
    return <AuthenticationStateConsumer name="fullscreen">{(value) => (!value ? this.RenderMenu() : <span></span>)}</AuthenticationStateConsumer>;
  }

  private RenderMenu(): React.ReactNode {
    if (isMobile) {
      return MobileHeader();
    } else {
      return <DesktopHeaderTranslated></DesktopHeaderTranslated>;
    }
  }
}

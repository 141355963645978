import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";
import vizygood from "../../assets/vizyshadowed.png";
import title from "../login/title.svg";
import "./login.scss";
import RestoreForm from "../login/resetpassword";

const ForgottenPassword = () => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("profile.resetPassword"),
      render: () => (
        <Tab.Pane>
          <RestoreForm />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div id="tabcontainer" style={{ padding: "20px" }}>
      <div>
        <img src={vizygood} />
      </div>
      <div style={{ margin: "20px 0 50px" }}>
        <img src={title} className="animated jackInTheBox" />
      </div>
      <Tab panes={panes} />
    </div>
  );
};

export default ForgottenPassword;

import React from "react";
import { useSelector } from "react-redux";
import GameConcentation from "./gameConcentration";
import Game from "./game";
import GamesDefinition from "../../../data/game-definition";
import i18next from "../../../i18n";
var images = require.context("../../../../public/images/games", true);

const Description = () => {
  let game = window.location.href.split("game=")[1];
  let currentGame;
  let id = null;
  let currentTitle = "";
  let key;
  if (game.includes("&")) {
    currentTitle = game.split("&id=")[0];
    id = game.split("&id=")[1];
    key = currentTitle;
  } else {
    key = game;
  }
  currentGame = GamesDefinition[key];

  currentGame.gameid = id;
  return <Game game={currentGame} />;
};

export default Description;

import { Form, Checkbox, Radio } from "semantic-ui-react";
import "./onboarding.scss";
import i18next from "i18next";

function DigitalForm({ digitalResults, setDigitalResults }) {

 
  const handleChange = async (_event, { name, value }) => {
    switch (name) {
      case "Ordinateur":
        setDigitalResults({ ...digitalResults, Ordinateur: !digitalResults.Ordinateur });
        break;
      case "Portable":
        setDigitalResults({ ...digitalResults, Portable: !digitalResults.Portable });
        break;
      case "Smartphone":
        setDigitalResults({ ...digitalResults, Smartphone: !digitalResults.Smartphone });
        break;
      case "Tablette":
        setDigitalResults({ ...digitalResults, Tablette: !digitalResults.Tablette });
        break;
      case "Liseuse":
        setDigitalResults({ ...digitalResults, Liseuse: !digitalResults.Liseuse });
        break;
      case "otherProfessionalDevices":
        setDigitalResults({ ...digitalResults, otherProfessionalDevices: !digitalResults.otherProfessionalDevices });
        break;
      case "ScreenTime":
        setDigitalResults({ ...digitalResults, ScreenTime: value });
        break;
      case "MultipleScreens":
        setDigitalResults({ ...digitalResults, MultipleScreens: value });
        break;
      case "RemoteWork":  
        setDigitalResults({ ...digitalResults, RemoteWork: value });
        break;
      case "SleepTime":
        setDigitalResults({ ...digitalResults, SleepTime: value });
        break;
      case "DigitalDeviceBlueFilter":
        setDigitalResults({ ...digitalResults, DigitalDeviceBlueFilter: value });
        break;
      default:
        break;
    }
  };

  const Digital = () => (
    <span>
      <Form.Field>
        <label>{i18next.t("tests.vitality.question3")}</label>
        <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer31')}
              name="Ordinateur"
              checked={digitalResults.Ordinateur}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer32')}
              name="Portable"
              checked={digitalResults.Portable}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer33')}
              name="Smartphone"
              checked={digitalResults.Smartphone}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer34')}
              name="Tablette"
              checked={digitalResults.Tablette}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer35')}
              name="Liseuse"
              checked={digitalResults.Liseuse}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label={i18next.t('tests.vitality.answer146')}
              name="otherProfessionalDevices"
              checked={digitalResults.otherProfessionalDevices}
              onChange={handleChange}
            />
          </Form.Field>
      </Form.Field>
    </span>
  );

  const MultipleScreens = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question4")}</label>
        <Form.Field>
            <Radio
              toggle
              label={i18next.t('common.yes')}
              name="MultipleScreens"
              value="Oui"
              checked={digitalResults.MultipleScreens == "Oui"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('common.no')}
              name="MultipleScreens"
              value="Non"
              checked={digitalResults.MultipleScreens == "Non"}
              onChange={handleChange}
            />
          </Form.Field>      </Form.Field>
    </span>
  );

  const RemoteWork = () => (
    <Form.Field>
      <br />
      <br />
      <label>{i18next.t("tests.vitality.question15")}</label>
      <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer151')}
            name="RemoteWork"
            value="Non, pas du tout"
            checked={digitalResults.RemoteWork == "Non, pas du tout"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer152')}
            name="RemoteWork"
            value="Oui, 1 à 2 jours par semaine en moyenne"
            checked={digitalResults.RemoteWork == "Oui, 1 à 2 jours par semaine en moyenne"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer153')}
            name="RemoteWork"
            value="Oui, 3 à 5 jours par semaine"
            checked={digitalResults.RemoteWork == "Oui, 3 à 5 jours par semaine"}
            onChange={handleChange}
          />
        </Form.Field>
    </Form.Field>
  );

  const SleepTime = () => (
    <Form.Field>
      <br />
      <br />
      <label>{i18next.t("tests.vitality.question16")}</label>
      <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer161')}
            name="SleepTime"
            value="moins de 5 heures"
            checked={digitalResults.SleepTime == "moins de 5 heures"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer162')}
            name="SleepTime"
            value="5 à 7 heures"
            checked={digitalResults.SleepTime == "5 à 7 heures"}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label={i18next.t('tests.vitality.answer163')}
            name="SleepTime"
            value="plus de 7 heures"
            checked={digitalResults.SleepTime == "plus de 7 heures"}
            onChange={handleChange}
          />
        </Form.Field>
    </Form.Field>
  );

  const DigitalDeviceBlueFilter = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question17")}</label>
        <Form.Field>
            <Radio
              toggle
              label={i18next.t('common.yes')}
              name="DigitalDeviceBlueFilter"
              value="Oui"
              checked={digitalResults.DigitalDeviceBlueFilter == "Oui"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('common.no')}
              name="DigitalDeviceBlueFilter"
              value="Non"
              checked={digitalResults.DigitalDeviceBlueFilter == "Non"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('tests.vitality.answer171')}
              name="DigitalDeviceBlueFilter"
              value="Je ne sais pas"
              checked={digitalResults.DigitalDeviceBlueFilter == "Je ne sais pas"}
              onChange={handleChange}
            />
          </Form.Field>
      </Form.Field>
    </span>
  );

  const ScreenTime = () => (
    <span>
      <br />
      <br />
      <Form.Field>
        <label>{i18next.t("tests.vitality.question5")}</label>
        <Form.Field>
            <Radio
              toggle
              label={i18next.t('tests.vitality.answer51')}
              name="ScreenTime"
              value="Moins de 4 heures"
              checked={digitalResults.ScreenTime == "Moins de 4 heures"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('tests.vitality.answer52')}
              name="ScreenTime"
              value="4 à 7 heures"
              checked={digitalResults.ScreenTime == "4 à 7 heures"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('tests.vitality.answer53')}
              name="ScreenTime"
              value="7 à 10 heures"
              checked={digitalResults.ScreenTime == "7 à 10 heures"}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={i18next.t('tests.vitality.answer54')}
              name="ScreenTime"
              value="Plus de 10 heures"
              checked={digitalResults.ScreenTime == "Plus de 10 heures"}
              onChange={handleChange}
            />
          </Form.Field>
      </Form.Field>
    </span>
  );

  return (
    <Form id="fatigue-form">
      <h3> </h3>
      {Digital()}
      {MultipleScreens()}
      {RemoteWork()}
      {SleepTime()}
      {DigitalDeviceBlueFilter()}
      {ScreenTime()}
    </Form>
  );
}

export default DigitalForm;

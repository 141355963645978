import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { isMobile } from "react-device-detect";
import { Button, Card, Grid, Image, Loader, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import i18next from "../../i18n";
import "./home.scss";

export function Actions({ state }) {
  if (state.loading) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  return (
    <Card style={{ width: "100%" }}>
      <Card.Content>
        <Card.Header className="head-title">{i18next.t("dashboard.actions.title")}</Card.Header>
        {GetActions()}
      </Card.Content>
    </Card>
  );
}
function GetActions() {
  const history = useHistory();

  if (!isMobile) {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "1em" }}>
        <Button
          style={{ margin: "5px", width: "180px" }}
          content={i18next.t("dashboard.actions.vitality")}
          color="blue"
          icon="eye"
          labelPosition="left"
          onClick={() => history.push("/onboarding")}
        />
        <Button
          style={{ margin: "5px", width: "180px" }}
          content={i18next.t("dashboard.actions.ergonomics")}
          color="blue"
          icon="thumbs up"
          labelPosition="left"
          onClick={() => history.push("/ergonomie")}
        />
        <Button
          style={{ margin: "5px", width: "180px" }}
          content={i18next.t("dashboard.actions.position")}
          color="blue"
          icon="thumbs up"
          labelPosition="left"
          onClick={() => history.push("/position")}
        />
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Button
          style={{ margin: "5px" }}
          content={i18next.t("dashboard.actions.vitality")}
          color="blue"
          icon="eye"
          labelPosition="left"
          onClick={() => history.push("/onboarding")}
        />
        <Button
          style={{ margin: "5px" }}
          content={i18next.t("dashboard.actions.ergonomics")}
          color="blue"
          icon="thumbs up"
          labelPosition="left"
          onClick={() => history.push("/ergonomie")}
        />
      </div>
    );
  }
}

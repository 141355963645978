import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Card, Image, Loader, Segment } from "semantic-ui-react";
import i18next from "../../i18n";
import concentration from "./concentration.png";
import detente from "./detente.png";
import { useSelector } from "react-redux/es/hooks/useSelector";
import pauses from "./pause.png";
import tonus from "./tonus.png";
import "./home.scss";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";
import { GetNextExcercise } from "../..//helpers/ProgramHelper";
import { useHistory } from "react-router-dom";

export function DailyProgram({ state, setState, isFull, setIsFull }) {
  const user = useSelector((state) => state.Auth.user);
  const history = useHistory();

  if (state.loading) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  const Train = async (type) => {
    let RandomGame = await GetNextExcercise(user.id, type);
    console.log("RandomGame", RandomGame);
    if (RandomGame[0]) {
      // Use history object to navigate to the game description page
      history.push(`/description?game=${RandomGame[0]}&id=${RandomGame[1]}`);
    } else {
      toast.success(i18next.t("dashboard.program.wellDone"), { duration: 5000 });
    }
  };

  const goFull = () => {
    setIsFull(true);
    console.log("goFull");
  };

  if (!state) {
    return (
      <Segment>
        <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  return (
    <Card style={{ width: "100%" }}>
      <Card.Content>
        <Card.Header className="head-title">{i18next.t("dashboard.program.title")}</Card.Header>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: "1em" }}>
          {Tasks(tonus, "Tonus", state.Program.Tonus.Done, state.Program.Tonus.ToDo, () => Train("Tonus"))}
          {Tasks(detente, "Detente", state.Program.Detente.Done, state.Program.Detente.ToDo, () => Train("Detente"))}
          {Tasks(concentration, "Posture", state.Program.Concentration.Done, state.Program.Concentration.ToDo, () => Train("Concentration"))}
          {Tasks(pauses, "Pause", state.Program.Pauses.Done, state.Program.Pauses.ToDo, () => goFull())}
        </div>
      </Card.Content>
    </Card>
  );
}

function Tasks(image, title, score, target, onClickHandler) {
  const items = [];
  for (let i = 0; i < target; i++) {
    items.push(
      <div key={i} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img style={{ width: "50px", filter: i < score ? "grayscale(100%)" : "none" }} alt="vizygood-game" src={image} />
      </div>
    );
  }

  return (
    <div
      onClick={onClickHandler}
      style={{ display: "flex", alignItems: "center", marginBottom: "10px", fontWeight: "bold", textTransform: "uppercase", color: "#2185d0", cursor: "pointer" }}
    >
      <span style={{ width: "180px", fontSize: "1.2em" }}>{i18next.t(`dashboard.program.${title}`)}</span>
      <span style={{ margin: "0 30px 0 10px", fontSize: "2.2em" }}>{target}</span>
      <div style={{ display: "flex", flexWrap: "wrap" }}>{items}</div>
    </div>
  );
}

//@ts-nocheck
import React, { useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { getGlobalState } from "../../components/state";
import i18next from "../../i18n";
import "./stats.scss";
import { Button } from "semantic-ui-react";

import api from "../../services/api";

export function GetExcercisesData(Scores) {
  return {
    labels: ["Pause", "Tonus", "Detente", "Posture"].map((label) => i18next.t("common." + label)),
    series: [
      [Scores?.Pause || 0, Scores?.Tonus || 0, Scores?.Detente || 0, Scores?.Concentration || 0],
      [Scores?.community?.Pause || 0, Scores?.community?.Tonus || 0, Scores?.community?.Detente || 0, Scores?.community?.Concentration || 0],
    ],
  };
}

export function GetCumulativeScores(userid, code, ExcercisesData, setScores) {
  const update = async function (target, days) {
    for (let button of target.parentElement.children) {
      button.classList.remove("active");
    }
    target.classList.add("active");
    const date = new Date();
    const ms = days ? date.setDate(date.getDate() - days) : new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    const newScores = await GetScores(userid, code, ms);
    setScores(newScores);
  };

  const company = getGlobalState("code") !== null;

  const data = ExcercisesData.labels.map((label, i) => ({
    id: label,
    [i18next.t("dashboard.stats.me")]: ExcercisesData.series[0][i],
    [i18next.t(`dashboard.stats.${company ? "coworkers" : "others"}`)]: ExcercisesData.series[1][i],
  }));

  const customColors = {
    [i18next.t("dashboard.stats.me")]: "#2185d0",
    [i18next.t(`dashboard.stats.${company ? "coworkers" : "others"}`)]: "#87cefa",
  };

  return (
    <div className="graph">
      <div style={{ flex: "1 1 0%", height: 150 }}>
        <ResponsiveBar
          data={data}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
          yFormat=" >-.2f"
          layout="horizontal"
          groupMode="grouped"
          keys={[i18next.t("dashboard.stats.me"), i18next.t(`dashboard.stats.${company ? "coworkers" : "others"}`)]}
          indexBy="id"
          margin={{ top: 0, right: 50, bottom: 50, left: 100 }}
          padding={0.3}
          colors={(bar) => customColors[bar.id]}
          enableLabel={false}
          enableGridX={true}
          enableGridY={true}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: "middle",
            tickValues: 5,
          }}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legendOffset: 36,
            legendPosition: "middle",
            tickValues: 5,
          }}
        />
      </div>
      <div className="settings">
        <Button.Group size="mini" vertical>
          <Button compact color="blue" onClick={(evt) => update(evt.target, 90)}>
            {i18next.t("dashboard.stats.last3Months")}
          </Button>
          <Button compact active color="blue" onClick={(evt) => update(evt.target, 30)}>
            {i18next.t("dashboard.stats.lastMonth")}
          </Button>
          <Button compact color="blue" onClick={(evt) => update(evt.target, 7)}>
            {i18next.t("dashboard.stats.lastWeek")}
          </Button>
          <Button compact color="blue" onClick={(evt) => update(evt.target)}>
            {i18next.t("dashboard.stats.today")}
          </Button>
        </Button.Group>
        <div className="legend">
          <div className="entry">
            <div className="legend-a"></div>
            <span>{i18next.t("dashboard.stats.me")}</span>
          </div>
          <div className="entry">
            <div className="legend-b"></div>
            <span>{i18next.t(`dashboard.stats.${company ? "coworkers" : "others"}`)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function GetScores(userid, code, from) {
  if (!from) {
    const date = new Date();
    from = date.setDate(date.getDate() - 30);
  }
  const result = await api.get(`/api/scores?from=${from}&code=${code}`);
  const Scores = { Tonus: 0, Concentration: 0, Detente: 0, Pause: 0, community: { Tonus: 0, Concentration: 0, Detente: 0, Pause: 0 } };
  const communityUsers = new Set();
  for (let item of result) {
    if (item.userId === userid) Scores[item.category] += item.score;
    else {
      communityUsers.add(item.userid);
      Scores.community[item.category] += item.score;
    }
  }
  const n = communityUsers.size;

  Scores.community.Tonus /= n;
  Scores.community.Concentration /= n;
  Scores.community.Detente /= n;
  Scores.community.Pause /= n;
  return Scores;
}

import React, { useEffect, useState } from "react";
import { Tab, Form, Button } from "semantic-ui-react";
import "./login.scss";
import vizygood from "../../assets/vizyshadowed.png";
import title from "../login/title.svg";
import i18next from "../../i18n";
import toast from "react-hot-toast";
var owasp = require("owasp-password-strength-test");
import axios from "../../helpers/axios";

const NewPasswordForm = () => {
  const [email, setEmail] = useState();
  const [state, setState] = useState({
    password: "",
    code: "",
    passwordREPEAT: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get("email"));
  }, []);

  const handeLogin = async () => {
    try {
      let passwordStrength = owasp.test(state.password);
      owasp.config({
        allowPassphrases: false,
        maxLength: 64,
        minLength: 10,
        minPhraseLength: 20,
        minOptionalTestsToPass: 4,
      });
      if (passwordStrength.errors.length >= 1) {
        toast.error("Le mot de passe doit comporter au moins 10 caractères et au moins une lettre majuscule, un chiffre et un caractère spécial.", { duration: 10000 });
        return false;
      }

      if (state.password == state.passwordREPEAT) {
        let response = await axios.post("/api/users/resetpassword", {
          code: state.code,
          password: state.password,
          email: email,
        });

        if (response.status == 200) {
          toast.success("Mot de passe changé avec succès", { duration: 5000 });
          window.location.href = "/";
        }
      }
    } catch (exception) {
      toast.error("Login Failed!", { duration: 5000 });
    }
  };

  const handleCodeChange = (e) => {
    let tmp = { ...state };
    tmp.code = e.target.value;
    setState(tmp);
  };
  const handlePasswordChange = (e) => {
    let tmp = { ...state };
    tmp.password = e.target.value;
    setState(tmp);
  };
  const handlePasswordRepeatChange = (e) => {
    let temp = { ...state };
    temp.passwordREPEAT = e.target.value;
    setState(temp);
  };

  return (
    <Form>
      <Form.Field>
        <label>Code</label>
        <input placeholder="code" type="number" onChange={(e) => handleCodeChange(e)} />
      </Form.Field>
      <Form.Field>
        <label>Mot de passe</label>
        <input placeholder="Mot de passe" type="password" onChange={handlePasswordChange} />
      </Form.Field>
      <Form.Field>
        <label>Répétez mot de passe</label>
        <input placeholder="Répétez mot de passe" type="password" onChange={handlePasswordRepeatChange} />
      </Form.Field>
      <Button type="submit" color="blue" onClick={handeLogin}>
        Restaurer
      </Button>
    </Form>
  );
};

const ResetPassword = () => (
  <div id="tabcontainer" style={{ padding: "20px" }}>
    <div>
      <img src={vizygood} />
    </div>
    <div style={{ margin: "20px 0 50px" }}>
      <img src={title} className="animated jackInTheBox" />
    </div>
    <Tab
      panes={[
        {
          menuItem: i18next.t("profile.resetPassword"),
          render: () => (
            <Tab.Pane>
              <NewPasswordForm />
            </Tab.Pane>
          ),
        },
      ]}
    />
  </div>
);
export default ResetPassword;

import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import "./login.scss";

import i18next from "../../i18n";

import api from "../../services/api";

import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const RestoreForm = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const res = await api.post("/api/mail/forgottenpassword", { email, language: i18next.language });
      if (res.ok) return history.push(`/passwordreset?email=${encodeURIComponent(email)}`);
      toast.error("No account matches this email");
    } catch (e) {
      console.log(e);
      toast.error("No account matches this email");
    }
  };

  return (
    <Form>
      <Form.Field>
        <label>{i18next.t("profile.email")}</label>
        <input placeholder={i18next.t("profile.email")} onChange={(e) => setEmail(e.target.value)} />
      </Form.Field>
      <Button type="submit" color="blue" onClick={handleLogin}>
        {i18next.t("profile.reset")}
      </Button>
    </Form>
  );
};

export default RestoreForm;

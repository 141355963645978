import * as Sentry from "@sentry/browser";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../services/api";
import { validateEmail } from "../../helpers/validateEmail";
import i18next from "../../i18n";
// import "./login.scss";

import BirthDate from "../../components/Birthdate";
import Gender from "../../components/Gender";

export default ({ onClose, open }) => {
  const [state, setState] = useState(null);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    setState(user);
  }, []);

  if (!state) return <div>loading..</div>;

  console.log(state);

  const handleSave = async () => {
    try {
      // let emailValid = process.env.REACT_APP_TEAMS === "1" || validateEmail(state.email);

      const obj = {};
      obj.firstName = state.firstName;
      obj.lastName = state.lastName;
      obj.email = state.email;
      obj.birthdate = state.birthdate;

      let data = await api.put("/api/users", obj);
      console.log(`data`, data);
      if (!data.ok) {
        return toast.error(data.code, { duration: 5000 });
      }
      toast.success("OK", { duration: 5000 });
      onClose();
    } catch (exception) {
      Sentry.captureException(exception);
      console.log(exception);
      toast.error(i18next.t("profile.emailOrPasswordError") + exception, { duration: 5000 });
    }
  };

  const handleInputChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  // todo
  // if (process.env.REACT_APP_TEAMS === "1") {
  //   const teamsfx = new TeamsFx();

  //   const graphClient = createMicrosoftGraphClient(teamsfx, ["User.Read"]); // Initializes MS Graph SDK using our MsGraphAuthProvider
  //   graphClient
  //     .api("/me")
  //     .get()
  //     .then(function (profile) {
  //       if (profile && state.firstname === "") setState({ ...state, firstname: profile.givenName, lastname: profile.surname, email: profile.mail });
  //     });
  // }

  return (
    <Modal size="tiny" closeOnDimmerClick={false} open={open} onClose={onClose}>
      <Modal.Header>{i18next.t("profile.title")}</Modal.Header>
      <Modal.Content>
        <Form style={{ padding: "20px" }}>
          <Form.Field>
            <label>{i18next.t("profile.firstname")}</label>
            <input name="firstName" value={state.firstName} onChange={handleInputChange} />
          </Form.Field>
          <Form.Field>
            <label>{i18next.t("profile.lastname")}</label>
            <input value={state.lastName} name="lastName" onChange={handleInputChange} />
          </Form.Field>
          <BirthDate value={new Date(state.birthdate)} onChange={handleInputChange} />
          <Gender value={state.gender} onChange={handleInputChange} />
          <Form.Field>
            <label>{i18next.t("profile.email")}</label>
            <input value={state.email} name="email" onChange={handleInputChange} disabled={true} />
          </Form.Field>
          <Modal.Actions>
            <Button onClick={onClose} negative>
              {i18next.t("common.cancel")}
            </Button>
            <Button onClick={handleSave} positive labelPosition="right" icon="checkmark" content={i18next.t("common.confirm")} color="blue" />
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

import "moment/locale/fr";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Image, Modal } from "semantic-ui-react";
import vizygood from "../../assets/vizyshadowed.png";
import i18next from "../../i18n";
import api from "../../services/api";

const ContactModal = ({ parametersModalShow, handleClose, CloseModal }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    // Perform any necessary side effects when the component mounts
    // For example, you can use this to fetch data or subscribe to events
  }, []);

  const handleValidate = async () => {
    try {
      const res = await api.post("/api/mail/contact", {
        message: value,
      });
      if (res.ok) toast.success(i18next.t("contact.feedback"), { duration: 5000 });
    } catch (e) {
      console.log(e);
      toast.error("Error when tying to contact us");
    }
    CloseModal();
  };

  return (
    <Modal size="tiny" closeOnDimmerClick={false} open={parametersModalShow} onClose={handleClose}>
      <Modal.Header>{i18next.t("contact.title")}</Modal.Header>
      <Modal.Content image>
        <Image wrapped size="medium" src={vizygood} style={{ maxWidth: "100px" }} />
        <Modal.Description>
          <p>{i18next.t("contact.text")}</p>
          <Form>
            <Form.Field
              control={TextareaAutosize}
              label={i18next.t("contact.question")}
              placeholder={i18next.t("contact.placeholder")}
              onChange={(e) => setValue(e.target.value)}
              useCacheForDOMMeasurements
              value={value}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} negative>
          {i18next.t("common.cancel")}
        </Button>
        <Button onClick={handleValidate} color="blue" positive labelPosition="right" icon="checkmark" content={i18next.t("common.confirm")} />
      </Modal.Actions>
    </Modal>
  );
};

export default ContactModal;

const forceProd = false;

const environment = getEnvironment();

let apiURL = "";
if (environment === "development") apiURL = "http://localhost:8080";
if (environment === "production") apiURL = "https://vizygood-web-api.azurewebsites.net/";

const SENTRY_DSN = "https://1112d38cdeca59d9275f5a5d81b35763@sentry.selego.co/88";

function getEnvironment() {
  //return "production";
  if (process.env.ENVIRONMENT === "production") return "production";
  if (window.location.href.indexOf("app-staging") !== -1) return "staging";
  if (window.location.href.indexOf("localhost") !== -1 || window.location.href.indexOf("127.0.0.1") !== -1) return "development";
  return "production";
}

export { apiURL, environment, SENTRY_DSN };

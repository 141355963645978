import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export function init() {
  const order = process.env.REACT_APP_LANGUAGEDETECTION ? process.env.REACT_APP_LANGUAGEDETECTION.split(',') : ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator','htmlTag'];
  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      load: 'languageOnly',
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr'],
      debug: true,
      detection: { order },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      }
    });
}

export default i18n;
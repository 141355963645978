import React, { useEffect } from "react";
import { Button, Icon, Grid } from "semantic-ui-react";
import { shuffle } from "../../../helpers/Utils";
import i18next from "../../../i18n";

let images = require.context("../../../../public/images/images3d/", true);

export function RandomImageButtons({ onClick, image }) {
  let newArray = [];

  newArray.push(
    <Button key="imageName" color="blue" onClick={(e) => onClick(e)} inverted>
      <Icon name="checkmark" /> {image}
    </Button>
  );
  newArray.push(
    <Button key="imageName1" color="blue" onClick={(e) => onClick(e)} inverted>
      <Icon name="checkmark" /> {getImage()}
    </Button>
  );
  newArray.push(
    <Button key="imageName2" color="blue" onClick={(e) => onClick(e)} inverted>
      <Icon name="checkmark" /> {getImage()}
    </Button>
  );
  newArray.push(
    <Button key="imageName3" color="blue" onClick={(e) => onClick(e)} inverted>
      <Icon name="checkmark" /> {getImage()}
    </Button>
  );
  return <div> {shuffle(newArray)}</div>;
}

function getImage() {
  let randomFileName = GetRandomFileName();
  randomFileName = i18next.t("images." + atob(randomFileName));
  return randomFileName;
}

function getVideo(context) {
  if (context.player.current) {
    return context.player.current.play();
  }
}

export function ImageGameButtonsPlay(context) {
  return (
    <div className="buttons">
      <Button type="submit" color="blue" onClick={() => getVideo(context)}>
        {i18next.t("game.help")}
      </Button>
      <Button
        type="submit"
        color="blue"
        onClick={() => {
          context.handleGameStart();
        }}
      >
        {i18next.t("game.exercise")}
      </Button>
    </div>
  );
}

export function ImageGameButtons(context) {
  return (
    <div className="buttons">
      <Button type="submit" color="blue" onClick={context.handleGameStart}>
        {i18next.t("game.help")}
      </Button>
      <Button
        type="submit"
        color="blue"
        onClick={() => {
          let state = context.state;
          state.modalOpen = true;
          context.setState(state);
        }}
      >
        {i18next.t("game.start")}
      </Button>
    </div>
  );
}

const GetImageName = (imageName) => {
  let str = imageName;
  try {
    str = images("./" + str + ".png");
  } catch (ex) {
    str = images("./" + str + ".jpg");
  }
  return str;
};

const GetRandomFileName = () => {
  let count = imageNames.length;
  let randomImage = Math.floor(Math.random() * count + 1) - 1;
  let randomFileName = imageNames[randomImage];
  return randomFileName;
};

const imageNames = [
  "QXZpb24=",
  "Qm91ZGRoYQ==",
  "Q2VyZg==",
  "Q2hhdA==",
  "Q2hldmFs",
  "Q29jaG9u",
  "Q29ldXI=",
  "Q29sb21iZQ==",
  "RWxlcGhhbnRz",
  "RXRvaWxl",
  "RmxldXI=",
  "R2xvYmVUZXJyZXN0cmU=",
  "R3VpdGFyZQ==",
  "SGlwcG9jYW1wZQ==",
  "TWFpbg==",
  "UGFwaWxsb24=",
  "UGxhbmV0ZQ==",
  "UG9pc3Nvbg==",
  "VGV0ZWRlQ2hhdA==",
  "VHJhaW5Mb2NvbW90aXZl",
  "VHJpY3ljbGU=",
  "Vm9pdHVyZQ==",
];

import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Form } from "semantic-ui-react";
import {
  HighFatigability,
  LowFatigability,
  MediumFatigability,
} from "../../data/fatigability";
import "./onboarding.scss";
import scoreDescription from "./ScoreDescription";
import VitalityGauge from "./VitalityGauge";
import i18next from "../../i18n";

function ParametersForm(props) {
  const [state, setState] = useState({
    Pauses: {},
    Excercises: {},
    Percentage: 0,
    Results: undefined,
  });

  useEffect(() => {
    async function fetchData() {
      if (props.previousState) {
        setState((prevState) => ({
          ...prevState,
          ...props.previousState,
        }));
      }
      if (props.finalScore) {
        const newState = { ...state };
        newState.Percentage = Math.round(props.finalScore.Percentage);

        newState.Results = getResults(newState.Percentage);
        if (newState.Results && newState.Results.Pauses) {
          newState.Pauses = newState.Results.Pauses;
        }
        if (newState.Results && newState.Results.Excercises) {
          newState.Excercises = newState.Results.Excercises;
        }

        props.handleStateChange("Parameters", newState);
        setState(newState);
      }
    }

    fetchData();
  }, []);

  function getResults(percentage) {
    if (percentage) {
      if (percentage >= 1 && percentage <= 30) {
        return LowFatigability;
      } else if (percentage >= 30 && percentage <= 65) {
        return MediumFatigability;
      } else if (percentage > 65) {
        return HighFatigability;
      }
      return {};
    }
  }

  const GetScoreDescription = () => {
    return (
      <div style={{ maxWidth: "50em", margin: "3em auto" }}>
        <p
          dangerouslySetInnerHTML={{
            __html: scoreDescription(100 - (state?.Percentage || 0)),
          }}
        />
        <p>{i18next.t("tests.vitality.result1")}</p>
        <p style={{ fontStyle: "italic", fontSize: "12px" }}>
          {i18next.t("tests.vitality.result2")}
        </p>
      </div>
    );
  };

  const handleStateChange = async (value) => {
    let newState = { ...state };
    if (value.Pauses) {
      newState.Pauses = value.Pauses;
    }
    if (value.Excercises) {
      newState.Excercises.hours = value.Excercises;
    }
    await props.handleStateChange("Parameters", newState);
    setState(newState);
  };

  const GetPauses = () => {
    if (state && state.Results) {
      return state.Results.Pauses;
    }
  };

  const GetExcercises = () => {
    if (state && state.Results) {
      return state.Results.Excercises;
    }
  };

  return (
    <Form id="fatigue-form">
      <br />
      <br />
      <h3
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        {i18next.t("common.vitalityIndex")}
        <span
          style={{
            margin: "0 8px",
            color: "rgba(0,0,0,.6)",
          }}
        >
          {state?.Percentage ? 100 - state.Percentage : ""}%
        </span>
      </h3>
      <Form.Field>
        <div
          style={{
            margin: "0 auto",
            width: isMobile ? 300 : 500,
          }}
        >
          {VitalityGauge(state)}
        </div>
        {GetScoreDescription()}
      </Form.Field>
    </Form>
  );
}

export default ParametersForm;

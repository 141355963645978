export function ScoreCalculation(state: any) {
  let finalScore = 0;
  let FrequencyScore = 0;
  switch (state.Eyes.Frequency) {
    case "Tous les ans": {
      FrequencyScore += 10;
      break;
    }
    case "Entre 2 et 4 ans": {
      FrequencyScore += 20;
      break;
    }
    case "Au-delà de 5 ans": {
      FrequencyScore += 100;
      break;
    }
    case "Jamais": {
      FrequencyScore += 500;
      break;
    }
  }

  let VisualProblemsScore = 0;
  if (state.Eyes.Headache) VisualProblemsScore += 600;
  if (state.Eyes.Larmoiements) VisualProblemsScore += 200;
  if (state.Eyes.Sechese) VisualProblemsScore += 200;
  if (state.Eyes.Picotements) VisualProblemsScore += 200;
  if (state.Eyes.VisionFloue) VisualProblemsScore += 400;
  if (state.Eyes.VisionDouble) VisualProblemsScore += 1000;
  if (state.Eyes.Migraines) VisualProblemsScore += 1000;
  if (state.Eyes.Concentration) VisualProblemsScore += 400;
  if (state.Eyes.VisualFatigue) VisualProblemsScore += 500;
  if (state.Eyes.OcularDolor) VisualProblemsScore += 400;
  if (state.Eyes.asleepDifficulty) VisualProblemsScore += 500;
  if (state.Eyes.otherDisorders) VisualProblemsScore += 100;

  let ToolsScore = 0;
  if (state.Digital.Ordinateur) ToolsScore += 60;
  if (state.Digital.Portable) ToolsScore += 100;
  if (state.Digital.Smartphone) ToolsScore += 400;
  if (state.Digital.Tablette) ToolsScore += 40;
  if (state.Digital.Liseuse) ToolsScore += 10;
  if (state.Digital.otherProfessionalDevices) ToolsScore += 60;

  let DigitalDeviceBlueFilter = 0;
  switch (state.Digital.DigitalDeviceBlueFilter.toLowerCase()) {
    case "oui": {
      DigitalDeviceBlueFilter += 10;
      break;
    }
    case "non": {
      DigitalDeviceBlueFilter += 30;
      break;
    }
    case "je ne sais pas": {
      DigitalDeviceBlueFilter += 20;
      break;
    }
  }

  let MultipleScreensScore = 0;
  if (state.Digital.MultipleScreens.toLowerCase() == "oui") MultipleScreensScore += 50;
  else MultipleScreensScore += 10;

  let RemoteWorkScore = 0;
  if (state.Digital.RemoteWork.toLowerCase() == "oui, 1 à 2 jours par semaine en moyenne") RemoteWorkScore += 200;
  else if (state.Digital.RemoteWork.toLowerCase() == "oui, 3 à 5 jours par semaine") RemoteWorkScore += 400;
  else RemoteWorkScore += 10;

  let SleepTimeScore = 0;
  if (state.Digital.SleepTime.toLowerCase() == "moins de 5 heures") SleepTimeScore = 1500;
  else if (state.Digital.SleepTime.toLowerCase() == "5 à 7 heures") SleepTimeScore = 1000;
  else SleepTimeScore = 10;

  let ScreenTimeScore = 0;
  switch (state.Digital.ScreenTime) {
    case "Moins de 4 heures": {
      ScreenTimeScore += 100;
      break;
    }
    case "4 à 7 heures": {
      ScreenTimeScore += 300;
      break;
    }
    case "7 à 10 heures": {
      ScreenTimeScore += 800;
      break;
    }
    case "Plus de 10 heures": {
      ScreenTimeScore += 1500;
      break;
    }
  }

  let VisualDefectScore = 0;
  if (state.VisualHealth.UnknownVisionProblem) VisualDefectScore += 20;
  if (state.VisualHealth.PasVisionDefect) VisualDefectScore += 10;
  
  let GlasseScore = 0;
  switch (state.VisualHealth.Glasses) {
    case "oui": {
      GlasseScore += 20;
      break;
    }
    case "non": {
      GlasseScore += 50;
      break;
    }
    case "Je ne porte pas de lunettes ni de lentilles": {
      GlasseScore += 10;
      break;
    }
  }
  let AntilumiereScore = 0;
  switch (state.VisualHealth.Antilumiere.toLowerCase()) {
    case "oui": {
      AntilumiereScore += 10;
      break;
    }
    case "non": {
      AntilumiereScore += 30;
      break;
    }
    case "je ne sais pas": {
      AntilumiereScore += 20;
      break;
    }
    case "je ne porte pas de lunettes": {
      AntilumiereScore += 10;
      break;
    }
  }

  let MedicineScore = 0;
  if (state.VisualHealth && state.VisualHealth.Medicines)
    switch (state.VisualHealth.Medicines.toLowerCase()) {
      case "oui": {
        MedicineScore += 20;
        break;
      }
      case "non": {
        MedicineScore += 60;
        break;
      }
    }
  let OrtoptieScore = 0;
  switch (state.VisualHealth.Ortoptie.toLowerCase()) {
    case "oui": {
      OrtoptieScore += 40;
      break;
    }
    case "non": {
      OrtoptieScore += 20;
      break;
    }
  }
  let AirConditioningScore = 0;
  switch (state.Ergonomy.Airconditioning.toLowerCase()) {
    case "oui": {
      AirConditioningScore += 40;
      break;
    }
    case "non": {
      AirConditioningScore += 10;
      break;
    }
    case "de temps en temps": {
      AirConditioningScore += 30;
      break;
    }
  }
  let ScreenScore = 0;
  switch (state.Ergonomy.ScreenHeight) {
    case "A la même hauteur que vos yeux": {
      ScreenScore += 40;
      break;
    }
    case "Plus haut que vos yeux": {
      ScreenScore += 300;
      break;
    }
    case "Plus bas que vos yeux": {
      ScreenScore += 200;
      break;
    }
    case "Cela dépend des situations": {
      ScreenScore += 100;
      break;
    }
  }
  let DeskScore = 0;
  switch (state.Ergonomy.Desk) {
    case "Perpendiculaire à une fenêtre": {
      DeskScore += 40;
      break;
    }
    case "Dos à une fenêtre": {
      DeskScore += 300;
      break;
    }
    case "Face à une fenêre": {
      DeskScore += 300;
      break;
    }
    case "Cela dépend des situations": {
      ScreenScore += 100;
      break;
    }
  }
  finalScore =
    FrequencyScore +
    VisualProblemsScore +
    ToolsScore +
    MultipleScreensScore +
    ScreenTimeScore +
    DigitalDeviceBlueFilter +
    VisualDefectScore +
    GlasseScore +
    AntilumiereScore +
    MedicineScore +
    OrtoptieScore +
    AirConditioningScore +
    ScreenScore +
    DeskScore;
  let percentage = (finalScore * 100) / 10660;
  let Level = "";
  if (percentage > 100) {
    percentage = 100;
  }
  if (percentage >= 1 && percentage <= 29) {
    Level = "Low";
  } else if (percentage >= 30 && percentage <= 65) {
    Level = "Medium";
  } else if (percentage > 65) {
    Level = "High";
  }
  return { Percentage: percentage, Level: Level };
}

import React from "react";
import "react-circular-progressbar/dist/styles.css";
import ReactCountdownClock from "react-countdown-clock";
import { isMobile } from "react-device-detect";
import { updatePauses } from "../../components/state";
import { InsertScore } from "../../helpers/GameHelper";
import i18next from "../../i18n";
import "./home.scss";
import api from "../../services/api";
import { useSelector } from "react-redux/es/hooks/useSelector";

export function CountDown(context: any, setState: any, isFull: boolean, setIsFull: any) {
  const user = useSelector((state: any) => state.Auth.user);
  let randomMessage = i18next.t(`pauses.pause${Math.floor(Math.random() * 8 + 1)}`);

  console.log(`context`, context);

  if (!isFull) return "";

  return (
    <div className="pause">
      <div id="pauses-container">
        <ReactCountdownClock
          seconds={60}
          color="white"
          alpha={0.9}
          size={150}
          onComplete={async () => {
            let random = Math.floor(Math.random() * 2) + 1;
            let audio = new Audio("/sounds/" + random + ".mp3");
            audio.play();
            await api.post("/api/PausesRecords", {
              id: 0,
              userid: user.id,
              date: new Date(),
            });
            let userData = await api.get("/api/users/scores");
            await InsertScore(user.id, "Pause", 200, "Pause", "Activity", "");
            let PausesScore = userData.PausesScore;
            if (PausesScore) {
              updatePauses(PausesScore.Percentage);
              setState((prevState: any) => ({ ...prevState, Program: {...prevState.Program, Pauses: userData.PausesScore}, loading: false }));
            }
            setIsFull(false);
          }}
        />
      </div>
      {GetTextMobileAware()}
    </div>
  );

  function GetTextMobileAware() {
    if (!isMobile) return <h2 className="text" dangerouslySetInnerHTML={{ __html: randomMessage }} />;
    return <h4 className="text" dangerouslySetInnerHTML={{ __html: randomMessage }} />;
  }
}

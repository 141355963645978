import "moment/locale/fr";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Stepper from "react-stepper-horizontal";
import { Button, Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import i18next from "../../i18n";
import ErgonomieQuestionsForm from "./ErgonomieQuestions";
import ErgonomieResults from "./ErgonomieResults";
import "./onboarding.scss";
import { StartStep } from "./Steps";
import { validations } from "./Validations";
import WebCamDistance from "./WebCamDistance";
import WebCamHeigth from "./WebCamHeigth";
import api from "../../services/api";
import { useHistory, useLocation } from "react-router-dom";

const Ergonomie = (props) => {
  const user = useSelector((state) => state.Auth.user);
  const defaultState = {
    step: -1,
    ErgonomieQuestions: {
      Position: "",
      MultipleScreens: false,
      DominantEye: "",
      ScreenSize: "",
      NaturalLight: "",
      ArtificialLight: "",
      WebCam: false,
      GoodHeight: false,
      GoodDistance: false,
    },
    WebCamQuestions: { WebCam: false },
    WebCamHeight: { GoodHeight: false },
    WebCamDistance: { GoodDistance: false, Distance: "" },
    Loading: false,
    Parameters: "",
    FinalScore: { Percentage: 0, Level: "" },
    started: false,
    onboardingFinished: false,
  };

  const [state, setState] = useState(defaultState);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get("/api/newergonomies");
      const newState = { ...state };
      if (result && result.newErgonomies && result.newErgonomies.length > 0) {
        const previousErgonomy = result.newErgonomies[0];
        newState.ErgonomieQuestions.Position = previousErgonomy.position;
        newState.ErgonomieQuestions.MultipleScreens = previousErgonomy.multiplescreens;
        newState.ErgonomieQuestions.DominantEye = previousErgonomy.dominanteye;
        newState.ErgonomieQuestions.ScreenSize = previousErgonomy.screensize;
        newState.ErgonomieQuestions.NaturalLight = previousErgonomy.naturallight;
        newState.ErgonomieQuestions.ArtificialLight = previousErgonomy.artificiallight;
        newState.ErgonomieQuestions.WebCam = previousErgonomy.webcam === 1 && window.innerWidth > 500;
        newState.WebCamHeight.GoodHeight = previousErgonomy.goodheight;
        newState.WebCamDistance.GoodDistance = previousErgonomy.gooddistance;
      }

      if (location.pathname.includes("/position")) {
        if (result && result.newErgonomies && result.newErgonomies.length > 0) {
          newState.step = 1;
        }
        //newState.ErgonomieQuestions.WebCam = false;
        setState(newState);

        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then(function (stream) {
            setState((prev) => ({ ...prev, ErgonomieQuestions: { ...prev.ErgonomieQuestions, WebCam: true } }));
            //handleNext();
          })
          .catch(function (e) {
            toast.error(i18next.t("common.webcamPermission"), { duration: 10000 });
            // setState((prev) => ({
            //   ...prev,
            //   ErgonomieQuestions: { ...prev.ErgonomieQuestions, WebCam: false },
            // }));
            handleNext();
          });
      } else handleNext();
    };

    fetchData();
  }, []);

  const handlePrevious = () => {
    if (state.step === StartStep) return;

    let newState = { ...state };
    if (newState.step === 3 && state.ErgonomieQuestions.WebCam === false) {
      newState.step = newState.step - 3;
    } else {
      newState.step = newState.step - 1;
    }

    setState(newState);
  };

  const handleNext = async () => {
    let newState = { ...state };

    if (!validations(state)) {
      toast.error(i18next.t("tests.answerAllQuestions"), { duration: 10000 });
      return;
    }

    if (state.step === 2) {
      return history.push("/dashboard");
    }

    if (state.step === 3) {
      const result = await api.post("/api/newergonomies", {
        id: 0,
        userid: user.id,
        position: state.ErgonomieQuestions.Position,
        multiplescreens: state.ErgonomieQuestions.MultipleScreens,
        dominanteye: state.ErgonomieQuestions.DominantEye,
        screensize: state.ErgonomieQuestions.ScreenSize,
        naturallight: state.ErgonomieQuestions.NaturalLight,
        artificiallight: state.ErgonomieQuestions.ArtificialLight,
        webcam: state.ErgonomieQuestions.WebCam,
        goodheight: state.ErgonomieQuestions.GoodHeight || state.WebCamHeight.GoodHeight,
        gooddistance: state.ErgonomieQuestions.GoodDistance || state.WebCamDistance.GoodDistance,
        distance: state.WebCamDistance.Distance,
        testdate: new Date(),
      });

      if (!result) {
        toast.error(i18next.t("tests.dataNotSaved"), { duration: 5000 });
        return;
      }

      toast.success(i18next.t("tests.dataSaved"), { duration: 5000 });
      history.push("/dashboard");
      return;
    }

    if (state.step === 0 && state.ErgonomieQuestions.WebCam === false) {
      newState.step = newState.step + 3;
    } else {
      newState.step = newState.step + 1;
    }

    setState(newState);
  };

  const handleStateChange = async (name, value) => {
    let s = { ...state };
    s[name] = value[name];
    setState(s);
  };

  const ShowTutorialPage = () => {
    switch (state.step) {
      case -1:
        return <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />;
      case 0: {
        return <ErgonomieQuestionsForm handleStateChange={handleStateChange} previousState={state}></ErgonomieQuestionsForm>;
      }
      case 1: {
        return <WebCamHeigth handleStateChange={handleStateChange} previousState={state.WebCamQuestions}></WebCamHeigth>;
      }
      case 2: {
        return <WebCamDistance handleStateChange={handleStateChange} previousState={state.WebCamQuestions} screenSize={state.ErgonomieQuestions.ScreenSize}></WebCamDistance>;
      }
      case 3: {
        return <ErgonomieResults handleStateChange={handleStateChange} results={state}></ErgonomieResults>;
      }
      default:
        return <div></div>;
    }
  };

  const GetButtons = () => {
    return (
      <div id="button-container">
        <Button type="submit" color="blue" onClick={handlePrevious} style={{ display: state.step <= 0 ? "none" : "block" }}>
          {i18next.t("common.previous")}
        </Button>
        <Button type="submit" color="blue" onClick={handleNext}>
          {i18next.t("common.next")}
        </Button>
      </div>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <div id="new-tabcontainer">{ShowTutorialPage()}</div>
      <div id="button-container" style={{ display: state.step < 0 ? "none" : "block" }}>
        {GetButtons()}
      </div>
      <div id="stepper" style={{ display: state.step < 0 ? "none" : "block" }}>
        <Stepper
          activeColor={"#62997A"}
          completeColor={"#62997A"}
          defaultColor={"#62997A"}
          steps={[{ title: "" }, { title: "" }, { title: "" }, { title: "" }]}
          activeStep={state.step}
        />
      </div>
    </div>
  );
};

export default Ergonomie;

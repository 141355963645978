const imageNames = [
  'QXZpb24=',
  'Qm91ZGRoYQ==',
  'Q2VyZg==',
  'Q2hhdA==',
  'Q2hldmFs',
  'Q29jaG9u',
  'Q29ldXI=',
  'Q29sb21iZQ==',
  'RWxlcGhhbnRz',
  'RXRvaWxl',
  'RmxldXI=',
  'R2xvYmVUZXJyZXN0cmU=',
  'R3VpdGFyZQ==',
  'SGlwcG9jYW1wZQ==',
  'TWFpbg==',
  'UGFwaWxsb24=',
  'UGxhbmV0ZQ==',
  'UG9pc3Nvbg==',
  'VGV0ZWRlQ2hhdA==',
  'VHJhaW5Mb2NvbW90aXZl',
  'VHJpY3ljbGU=',
  'Vm9pdHVyZQ==',
];

export default imageNames;

import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { init } from "./i18n";
import { SENTRY_DSN } from "./config";
import "./index.scss";

import store from "./redux/store";

init().then(function () {
  Sentry.init({
    dsn: SENTRY_DSN,
  });

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

  const state = {};

  // eslint-disable-next-line no-restricted-globals
  addEventListener(
    "load",
    function () {
      window.scrollTo(1, 0);
    },
    false
  );

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
});

import React from "react";
import { isMobile } from "react-device-detect";
import i18next from "i18next";
import GameCard from "./GameCard";
import ImageCard from "./ImageCard";
import "./games.scss";

class Games extends React.Component {
  getParams() {
    if (isMobile) {
      return { slidesPerView: 2, spaceBetween: 10, freeMode: true };
    } else {
      return { slidesPerView: 3, spaceBetween: 5, freeMode: true };
    }
  }

  render() {
    return (
      <div style={{ padding: "2em 1em" }}>
        <p className="title">{i18next.t("common.Tonus")}</p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <GameCard name="LeCrayon" />
          <GameCard name="PingPongH" />
          <GameCard name="PingPongV" />
          <GameCard name="LeV" />
          <GameCard name="LoinPres" />
          <GameCard name="SaccadesDynamiquesHorizontales" />
        </div>

        <p className="title">{i18next.t("common.Detente")}</p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <GameCard name="Horloge" />
          <GameCard name="LaCroix" />
          <GameCard name="LeDemiCercleDroite" />
          <GameCard name="Palming" />
          <GameCard name="LeX" />
          <GameCard name="Infini" />
        </div>

        <p className="title">{i18next.t("common.Posture")}</p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <GameCard name="CouHorizontal" />
          <GameCard name="CouAvantArriere" />
          <GameCard name="CouDiagonal" />
          <GameCard name="DosEtirements" />
          <GameCard name="EpaulesEtirementsHautBas" />
          <GameCard name="DosJambesEtirements" />
        </div>

        <p className="title">{i18next.t("common.gallery")}</p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <GameCard name="Conv3D" />
          <GameCard name="Div3D" />
          <ImageCard name="Etoile" />
          <ImageCard name="Hippocampe" />
          <ImageCard name="Guitare" />
          <ImageCard name="Cerf" />
          {/* <ImageCard name="Papillon" />
          <ImageCard name="Main" />
          <ImageCard name="TetedeChat" />
          <ImageCard name="Colombe" />
          <ImageCard name="Poisson" />
          <ImageCard name="Tricycle" />
          <ImageCard name="Fleur" />
          <ImageCard name="Cochon" />
          <ImageCard name="GlobeTerrestre" />
          <ImageCard name="Planete" />
          <ImageCard name="Elephants" />
          <ImageCard name="TrainLocomotive" />
          <ImageCard name="Coeur" />
          <ImageCard name="Bouddha" />
          <ImageCard name="Chat" />
          <ImageCard name="Avion" />
          <ImageCard name="Voiture" />
          <ImageCard name="Cheval" /> */}
        </div>
      </div>
    );
  }
}

export default Games;

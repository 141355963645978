import i18next from '../../i18n';

export default function scoreDescription(percentage: number | undefined) {
  if (percentage === undefined) return "";

  let id;
  if (percentage <= 20)
    id = 'lowerThan20';
  else if (percentage <= 30)
    id = 'between21And30'
  else if (percentage <= 50)
    id = 'between31And50'
  else if (percentage <= 65)
    id = 'between51And65'
  else if (percentage <= 84)
    id = 'between66And84'
  else
    id = 'higherThan85';

  const title = i18next.t(`tests.vitality.results.${id}.title`)
  const description = i18next.t(`tests.vitality.results.${id}.description`);
  return `<p style="text-align: center; text-transform: uppercase">${title}</p><p>${description}</p>`;
};
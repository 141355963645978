import i18next from "i18next";
import React from "react";
import { Popup } from "semantic-ui-react";
import "./gauge.scss";

export default function VitalityGauge(state: any) {
  const ticks = [];
  for (let i = 0; i <= 100; i += 50) {
    const left = i === 0 ? '0px' : i === 50 ? '50%' : '95%';
    ticks.push(<span key={i} style={{ fontSize: "10px", left, position: "absolute", top: "-20px" }}>{i}%</span>)
  }
  return (
    <div style={{ height: "20px", margin: "10px 0 3px", width: '100%', position: "relative" }}>
      {ticks}
      <Popup position="top center" content={i18next.t('common.vitalityExplanations')} trigger={
        <div style={{ backgroundImage: "linear-gradient(to right, #d91f05, #e54f00, #ef7100, #f69100, #fcaf00, #f2bd00, #e6cb00, #d8d900, #b9d900, #97d900, #6ed812, #2cd62a)" }}>
          <span className="triangle" style={{ marginLeft: state?.Percentage ? (100 - state.Percentage) + "%" : 0 }}></span>
        </div>
      } />
    </div>
  );
}
import React, { useState, useEffect, useRef } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import { ControlBar, Player } from "video-react";
import NestedModal from "../../../components/ExcerciseModalClean";
import { updateGames, updateScores } from "../../../components/state";
import { InsertScore } from "../../../helpers/GameHelper";
import i18next from "../../../i18n";
import "./game.scss";
import { RandomImageButtons } from "./ImageExcercises";
import { useSelector } from "react-redux";
import "./video-react.css"; // import cssport scss
import api from "../../../services/api";
import imageNames from "./imageNames";
import { useHistory } from "react-router-dom";
const images = require.context("../../../../public/images/images3d/", true);
const videos = require.context("../../../../public/videos/", true);

const Game = ({ game }) => {
  const player = useRef(null);
  const history = useHistory();
  const [hasBeenPlayed, setHasBeenPlayed] = useState();
  const [videofile, setVideofile] = useState("");
  const [playVideo, setPlayVideo] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imageName, setImageName] = useState("");
  const [newGame, setNewGame] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState({ open: false, message: "", title: "" });
  const [excerciseResultSucessfull, setExcerciseResultSucessfull] = useState(false);

  const handleGameStart = async () => {
    player.current.play();
  };

  const handleGameEnd = async (game) => {
    if (game.interactive === "true") return;
    OpenPopup(i18next.t("game.great"), i18next.t("game.result1", { points: game.points }), true);
    let games = await UpdateScore();
    if (games) {
      updateGames(games);
    }
  };

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const fetchUserData = async () => {
      const hasBeenPlayed = await api.get(`/api/scores?activity=${game.title}&userid=${user._id}&category=${game.category}`);
      setHasBeenPlayed(hasBeenPlayed && hasBeenPlayed.length > 0);
      return hasBeenPlayed;
    };

    const getInitialData = (hasBeenPlayed) => {
      let video = `instructions/${game.key}.mp4`;
      setVideofile(video);

      if (game.interactive == "true") {
        let randomFileName = GetRandomFileName();
        setImageName(i18next.t("images." + atob(randomFileName)));
        setImageFile(GetImageName(randomFileName));
        return;
      }

      player.current.video.handleEnded = async () => {
        if (videofile.indexOf("instructions") === -1) {
          handleGameEnd(game);
        }
      };
    };

    const initData = async () => {
      const hasBeenPlayed = await fetchUserData();
      getInitialData(hasBeenPlayed);
    };

    initData();
  }, [game, hasBeenPlayed, newGame]);

  useEffect(() => {
    const handleVideoEnd = () => {
      if (videofile && videofile.indexOf("instructions") === -1) {
        handleGameEnd(game);
      }
    };

    if (player.current && player.current.video) {
      player.current.video.handleEnded = handleVideoEnd;
    }
  }, [videofile, game]);

  const GetRandomFileName = () => {
    const validImageNames = imageNames.filter((imageName) => {
      try {
        atob(imageName);
        return true; 
      } catch (error) {
        return false;
      }
    });

    // Check if there are any valid image names
    if (validImageNames.length === 0) {
      throw new Error("No valid base64-encoded image names found in the imageNames array.");
    }

    // Pick a random valid base64-encoded string from the filtered array
    const randomImage = Math.floor(Math.random() * validImageNames.length);
    const randomFileName = validImageNames[randomImage];
    return randomFileName;
  };

  const GetImageName = (imageName) => {
    try {
      imageName = images("./" + imageName + ".png");
    } catch (ex) {
      imageName = images("./" + imageName + ".jpg");
    }
    return imageName;
  };

  const UpdateScore = async () => {
    await InsertScore(user.id, game.category, game.points, game.title, game.type, game.gameid);
    let userData = await api.get("/api/users/scores");
    let games = userData.ExcercisesScore;
    updateScores(userData.Scores);
    return games;
  };

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const handleImageChosen = async (event) => {
    let selectedImage = event.currentTarget.innerText.trim();
    if (selectedImage === imageName.trim()) {
      OpenPopup(i18next.t("game.great"), i18next.t("game.result2") + " " + i18next.t("game.result1", { points: game.points }), true);
      await UpdateScore();
    } else {
      OpenPopup(i18next.t("game.shame"), i18next.t("game.result3"), false);
    }
  };

  const handleNestedModalClose = () => {
    if (excerciseResultSucessfull) {
      setModalOpen(false);
      history.push("/#/home");
    }
    setNestedModalOpen({ open: false });
  };

  const OpenPopup = (title, message, success) => {
    setExcerciseResultSucessfull(success);
    setNestedModalOpen({ open: true, title: title, message: message });
  };

  const GetExerciseButton = () => {
    if (game.interactive === "false") {
      return (
        <Button
          type="submit"
          color="blue"
          onClick={() => {
            setVideofile(`exercise/${game.key}.mp4`);
            setPlayVideo(true);
          }}
        >
          {i18next.t("game.exercise")}
        </Button>
      );
    } else {
      return (
        <Button
          type="submit"
          color="blue"
          onClick={() => {
            setNewGame(!newGame);
            setModalOpen(true);
          }}
        >
          {i18next.t("game.exercise")}
        </Button>
      );
    }
  };

  useEffect(() => {
    if (playVideo && player && player.current && player.current.video && typeof player.current.video.play === "function") {
      player.current.video.play();
      setPlayVideo(false);
    }
  }, [videofile, playVideo]);

  return (
    <div style={{ height: "100%" }}>
      <NestedModal open={nestedModalOpen.open} title={nestedModalOpen.title} message={nestedModalOpen.message} onClose={handleNestedModalClose} />
      <Modal closeOnEscape={false} closeOnDimmerClick={false} open={modalOpen} onClose={handleClose} basic size="fullscreen">
        <Modal.Content>
          <Image src={imageFile} style={{ maxHeight: "70vh", marginLeft: "auto", marginRight: "auto" }} />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: "center" }}>
            <RandomImageButtons onClick={handleImageChosen} image={imageName.trim()} />
            <Button color="red" onClick={handleClose} inverted>
              <Icon name="cancel" /> {i18next.t("common.cancel")}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>

      <div className="gameName">{i18next.t(`games.${game.key}.title`)}</div>
      <div className="game">
        <div className="video">
          <Player
            playsInline
            ref={player}
            src={`${videofile ? videos("./" + videofile) : ""}`}
            poster={`${videofile ? videos("./posters/" + videofile.replace("mp4", "jpg")) : ""}`}
          >
            <ControlBar autoHide={false} />
          </Player>
          <div className="buttons">
            <Button
              type="submit"
              color="blue"
              onClick={() => {
                setVideofile(`instructions/${game.key}.mp4`);
                setPlayVideo(true);
              }}
            >
              {i18next.t("game.instructions")}
            </Button>
            {GetExerciseButton()}
          </div>
          <div className="benefits">
            <div className="benefits1">{i18next.t(`game.benefits`)}</div>
            <div className="benefits2">{i18next.t(`games.${game.key}.benefits`)}</div>
          </div>
        </div>
        <div className="text">
          <div className="summary">
            <div className="repetitions">
              <Icon name="repeat" inverted circular />
              <div className="textIcon">{i18next.t(`games.${game.key}.repetitions`)}</div>
            </div>
            <div className="time">
              <Icon name="clock" inverted circular />
              <div className="textIcon">{i18next.t(`games.${game.key}.time`)}</div>
            </div>
            <div className="material">
              <Icon name="pencil" inverted circular />
              <div className="textIcon">{i18next.t(`games.${game.key}.material`)}</div>
            </div>
            <div className="level">
              <Icon name="trophy" inverted circular />
              <div className="textIcon">{i18next.t(`game.level${game.level}`)}</div>
            </div>
            <div className="tip">
              <Icon name="idea" inverted circular />
              <div className="textIcon">{i18next.t(`games.${game.key}.tip`)}</div>
            </div>
          </div>
          <div className="instructions" dangerouslySetInnerHTML={{ __html: i18next.t(`games.${game.key}.instructions`) }} />
        </div>
      </div>
    </div>
  );
};

export default Game;

import i18next from "i18next";
import React from "react";
import { Form } from "semantic-ui-react";
import vizygood from "../../assets/vizyshadowed.png";
import "./onboarding.scss";
interface IProps {
  handleStateChange: (name: string, value: any) => any;
  results: any;
}
interface IState {
  Pauses: any;
  Excercises: any;
  Percentage: number;
  Results: any;
}
export default class ErgonomieQuestions extends React.Component<
  IProps,
  IState
> {
  render() {
    const advices: { [key: string]: Array<string> } = { good: [], bad: [] };
    this.ScreenPosition(advices);
    this.ScreenDistance(advices);
    this.ScreenHeight(advices);
    this.ScreenNumber(advices);
    this.NaturalLight(advices);
    this.ArtificialLight(advices);

    const good = [], bad = [];
    if (advices.good.length) {
      good.push(<h3>{i18next.t('tests.ergonomics.results.wellDone')}</h3>);
      for (let item of advices.good) {
        good.push(<h4>{i18next.t(`tests.ergonomics.results.${item[0]}`)}</h4>);
        good.push(<p>{i18next.t(`tests.ergonomics.results.${item[1]}`, item[2])}</p>);
      }
    }
    if (advices.bad.length) {
      bad.push(<h3>{i18next.t('tests.ergonomics.results.improvements')}</h3>);
      for (let item of advices.bad) {
        bad.push(<h4>{i18next.t(`tests.ergonomics.results.${item[0]}`)}</h4>);
        bad.push(<p>{i18next.t(`tests.ergonomics.results.${item[1]}`, item[2])}</p>);
      }
    }

    return (
      <Form id="fatigue-form">
        <h2 style={{ margin: "30px 0", textAlign: "center" }}>{i18next.t('tests.ergonomics.results.title')}</h2>
        <div style={{ textAlign: "center" }}>
          <img src={vizygood} style={{ width: "75px", marginBottom: "20px" }} />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div className="good" style={{ display: good.length ? 'block' : 'none', margin: bad.length ? '10px' : '10px auto', width: '45%', minWidth: '30em', maxWidth: '40em' }}>{good}</div>
          <div className="bad" style={{ display: bad.length ? 'block' : 'none', margin: good.length ? '10px' : '10px auto', width: '45%', minWidth: '30em', maxWidth: '40em' }}>{bad}</div>
        </div>
      </Form>
    );
  }
  handleStateChange = async () => { };

  private ScreenPosition = (advices: any) => {
    let Advice;
    if (this.props.results.ErgonomieQuestions?.Position == "Sur le coté")
      advices.bad.push(['resultSP', 'adviceSP1']);
    else
      advices.good.push(['resultSP', 'adviceSP2']);

    return Advice;
  };
  private ScreenDistance = (advices: any) => {
    let Advice;
    let screenSize = this.props.results.ErgonomieQuestions.ScreenSize;
    let idealdistance = "";
    if (screenSize == "Moins de 13 pouces") {
      idealdistance = "<50 cm";
    } else {
      let screenSizeInch = screenSize.split(" ")[0];
      switch (screenSizeInch) {
        case "13": {
          idealdistance = "50 cm";
          break;
        }
        case "14": {
          idealdistance = "54 cm";
          break;
        }
        case "15": {
          idealdistance = "57 cm";
          break;
        }
        case "16": {
          idealdistance = "62 cm";
          break;
        }
        case "17": {
          idealdistance = "65 cm";
          break;
        }
        case "18": {
          idealdistance = "69 cm";
          break;
        }
        case "19": {
          idealdistance = "72 cm";
          break;
        }
        case "20": {
          idealdistance = "77 cm";
          break;
        }
        case "21": {
          idealdistance = "80 cm";
          break;
        }
      }

      if (this.props.results.ErgonomieQuestions.GoodDistance || (this.props.results.ErgonomieQuestions.WebCam && this.props.results.WebCamDistance.GoodDistance))
        advices.good.push(['resultSD', 'adviceSD2']);
      else
        advices.bad.push(['resultSD', 'adviceSD1', { idealdistance }]);
    }
    return Advice;
  };
  private ScreenHeight = (advices: any) => {
    let Advice;
    if (this.props.results.ErgonomieQuestions.GoodHeight || (this.props.results.ErgonomieQuestions.WebCam && this.props.results.WebCamHeight.GoodHeight))
      advices.good.push(['resultSH', 'adviceSH2']);
    else
      advices.bad.push(['resultSH', 'adviceSH1']);
    return Advice;
  };
  private ScreenNumber = (advices: any) => {
    let Advice;

    if (this.props.results.ErgonomieQuestions) {
      if (this.props.results.ErgonomieQuestions.MultipleScreens == true)
        advices.bad.push(['resultSN', 'adviceSN1']);
      else
        advices.good.push(['resultSN', 'adviceSN2']);
      return Advice;
    }
  };
  private NaturalLight = (advices: any) => {
    let Advice;

    if (this.props.results.ErgonomieQuestions) {
      if (this.props.results.ErgonomieQuestions.NaturalLight == "De coté")
        advices.good.push(['resultNL', 'adviceNL1']);
      else if (this.props.results.ErgonomieQuestions.NaturalLight == "De devant")
        advices.bad.push(['resultNL', 'adviceNL2']);
      else if (this.props.results.ErgonomieQuestions.NaturalLight == "De derrière")
        advices.bad.push(['resultNL', 'adviceNL3']);
      else if (this.props.results.ErgonomieQuestions.NaturalLight == "Il n'y en a pas")
        advices.bad.push(['resultNL', 'adviceNL4']);
    }
    return Advice;
  };
  private ArtificialLight = (advices: any) => {
    let Advice;

    if (this.props.results.ErgonomieQuestions) {
      if (this.props.results.ErgonomieQuestions.ArtificialLight == "Plafonnier")
        advices.bad.push(['resultAL', 'adviceAL1']);
      else if (this.props.results.ErgonomieQuestions.ArtificialLight == "Lampe d'appoint")
        advices.good.push(['resultAL', 'adviceAL2']);
      else if (this.props.results.ErgonomieQuestions.ArtificialLight == "Halogène")
        advices.good.push(['resultAL', 'adviceAL3']);
      else if (this.props.results.ErgonomieQuestions.ArtificialLight == "Il n'y en a pas")
        advices.bad.push(['resultAL', 'adviceAL4']);
    }
    return Advice;
  };
}

import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import { StateProvider } from "./components/state";
//@ts-ignore

import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import * as Sentry from "@sentry/react";

import Header from "./components/header/header";
import Dashboard from "./pages/dashboard/index";
import Ergonomie from "./pages/ergonomie/index";
import ForgottenPassword from "./pages/forgottenpassword";
import ResetPassword from "./pages/forgottenpassword/ResetPassword";
import Description from "./pages/games/description/index";
import Games from "./pages/games/index";
import Iframe from "./pages/iframe/index";
import LegalMentions from "./pages/legalmentions/index";
import Login from "./pages/login/index";
import Onboarding from "./pages/onboarding";
import Pauses from "./pages/pauses";
import Stats from "./pages/stats/index";
import api from "./services/api";
import { setUser } from "./redux/auth/actions";
const { SENTRY_DSN } = require("./config");

Sentry.init({
  dsn: SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  environment: "app",
  tracesSampleRate: 1.0,
});

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await api.get(`/api/users/me`);
        if (!res.error) dispatch(setUser(res));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getUser();

    const receiveMessage = (event) => {
      localStorage.setItem("firebase-token", event.data);
      window.removeEventListener("message", receiveMessage, false);
    };
    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  const user = useSelector((state) => state.Auth.user);

  if (isLoading) return <Loader active size="big" inline="centered" style={{ margin: "1em auto" }} />;

  return (
    <StateProvider>
      <div id="app" className="container-fluid" style={user ? stylesWhite.container : styles.container}>
        <Suspense fallback="loading">
          <Router>
            <Toaster />
            {user ? <Header /> : ""}
            <div style={{ flexGrow: 1, overflowX: "hidden", overflowY: "auto" }}>
              <Switch>
                <Route path="/forgottenpassword" component={ForgottenPassword} />
                <Route path="/auth" component={Login} />
                <Route path="/passwordreset" component={ResetPassword} />
                <RestrictedRoute path="/games" component={Games} />
                <RestrictedRoute path="/iframe" component={Iframe} />
                <RestrictedRoute path="/description" component={Description} />
                <RestrictedRoute path="/onboarding" component={Onboarding} />
                <RestrictedRoute path="/pause" component={Pauses} />
                <RestrictedRoute path="/stats" component={Stats} />
                <RestrictedRoute path="/ergonomie" component={Ergonomie} />
                <RestrictedRoute path="/position" component={Ergonomie} />
                <RestrictedRoute path="/legalmentions" component={LegalMentions} />
                <RestrictedRoute path="/home" component={Dashboard} />
                <RestrictedRoute path="/" component={Dashboard} />
              </Switch>
            </div>
          </Router>
        </Suspense>
      </div>
    </StateProvider>
  );
};

const RestrictedRoute = ({ component: Component, role, ...rest }) => {
  const user = useSelector((state) => state.Auth.user);
  console.log(`user`, user);
  if (!user || (role === "admin" && user.role !== "admin")) return <Redirect to={{ pathname: "/auth" }} />;
  return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Redirect to={{ pathname: "/auth" }} />)} />;
};

const styles = { container: { background: "linear-gradient(180deg, #245AB8 0%, #2682AC 100%)", height: "100%", display: "flex", flexDirection: "column" } };
const stylesWhite = { container: { background: "white", height: "100%", display: "flex", flexDirection: "column" } };

export default App;
